import React, { FC } from 'react'
import cls from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Game, IGame } from 'protos'
import {
  Checkmark,
  GameStateWin,
  GameStateDraw,
  GameStateLive,
  GameStateLoss,
  GameStatePending,
  GameStateRefunded,
} from 'tackl-material/Icons'

interface Props extends SvgIconProps {
  state: IGame['state']
}

const useStyles = makeStyles({
  mirrorY: {
    transform: 'scaleY(-1)',
  },
})

const IndicatorIcon: FC<Props> = ({ state, ...props }) => {
  const classes = useStyles()

  switch (state) {
    case Game.State.GS_FINISHED_DRAW:
      return <GameStateDraw {...props} />
    case Game.State.GS_FINISHED_LOSS:
      return (
        <GameStateLoss
          {...props}
          className={cls(props.className, classes.mirrorY)}
        />
      )
    case Game.State.GS_FINISHED_WIN:
      return <GameStateWin {...props} />
    case Game.State.GS_RUNNING_LIVE:
    case Game.State.GS_RUNNING_WAITING:
      return <GameStateLive {...props} />
    case Game.State.GS_PENDING:
      return <GameStatePending {...props} />
    case Game.State.GS_FINISHED_OTHER:
      return <GameStateRefunded {...props} />
    case Game.State.GS_UPCOMING:
    default:
      return <Checkmark {...props} />
  }
}

export default IndicatorIcon
