import React, { FC } from 'react'
import cls from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Game, GameDetails } from 'protos'
import { Markdown } from 'components'
import { Box, Typography, Divider } from 'tackl-material'

const GameState = Game.State

type Props = {
  gameState: Game.State
  drinkPrizeDetails: GameDetails.IDrinkPrizeDetails
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
  lossResult: {
    color: palette.gameState[GameState.GS_FINISHED_LOSS].main,
  },
  winResult: {
    color: palette.gameState[GameState.GS_FINISHED_WIN].main,
  },
  coloredDivider: {
    backgroundColor: 'currentColor',
  },
  defaultColored: {
    color: 'initial',
  },
}))


const DrinkGamePrizeDistribution: FC<Props> = ({ gameState, drinkPrizeDetails }) => {
  const classes = useStyles()

  return (
    <article>
      <Box
        mb={1}
        component="header"
      >
        <Typography
          gutterBottom
          variant="h6"
          component="div"
        >
          {drinkPrizeDetails.title}
        </Typography>
        {
          drinkPrizeDetails.wager && (
            <Typography
              noWrap
              variant="h5"
              component="div"
            >
              <Box
                mr={1}
                component="span"
              >
                {drinkPrizeDetails.wager.unit}
              </Box>
              {drinkPrizeDetails.wager.drinkName}
            </Typography>
          )
        }
      </Box>

      {
        drinkPrizeDetails.helpText && (
          <>
            <Divider light />
            <Box
              my={1}
              component="p"
            >
              <Typography variant="subtitle1">
                <Markdown>{drinkPrizeDetails.helpText}</Markdown>
              </Typography>
            </Box>
          </>
        )
      }

      {
        drinkPrizeDetails.topPrize && (
          <>
            <Divider className={classes.coloredDivider} />
            <Box
              my={1}
              display="flex"
              component="footer"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Box
                pr={1}
                flex={1}
                maxWidth="100%"
              >
                <Typography variant="subtitle1">
                  <Markdown>{drinkPrizeDetails.topPrizeTitle}</Markdown>
                </Typography>
                <Typography
                  noWrap
                  variant="h5"
                  component="div"
                >
                  {drinkPrizeDetails.topPrize.drinkName}
                </Typography>
              </Box>
              <span>
                {drinkPrizeDetails.topPrize.unit}
              </span>
            </Box>
          </>
        )
      }

      {
        drinkPrizeDetails.ownPrize && (
          <div
            className={cls({
              [classes.winResult]: gameState === GameState.GS_FINISHED_WIN,
              [classes.lossResult]: gameState === GameState.GS_FINISHED_LOSS,
            })}
          >
            <Divider className={classes.coloredDivider} />
            <Box
              mt={1}
              display="flex"
              component="footer"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Box
                pr={1}
                flex={1}
                maxWidth="100%"
              >
                <Typography
                  variant="subtitle1"
                  className={classes.defaultColored}
                >
                  <Markdown>{drinkPrizeDetails.ownPrizeTitle}</Markdown>
                </Typography>
                <Typography
                  noWrap
                  variant="h5"
                  component="div"
                  color="inherit"
                >
                  {drinkPrizeDetails.ownPrize.drinkName}
                </Typography>
              </Box>
              <span>
                {drinkPrizeDetails.ownPrize.unit}
              </span>
            </Box>
          </div>
        )
      }
    </article>
  )
}

export default DrinkGamePrizeDistribution
