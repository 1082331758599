import marked from 'marked'
import { vsprintf } from 'sprintf-js'

export const parsePlainText = (message: string, values?: Array<string | number>): string => {
  try {
    if (values && values.length > 0) {
      return vsprintf(message, values)
    }

    return message
  } catch (error) {
    console.error({ error, message, values })
    return message
  }
}

export const parseMarkdown = (message: string, values?: Array<string | number>): string => {
  try {
    const renderer = new marked.Renderer()
    renderer.text = value => value.replace(/&amp;/g, '&')
    renderer.paragraph = value => value

    return marked(parsePlainText(message, values) || '', {
      renderer,
      gfm: true,
      breaks: true,
      smartypants: true,
    })
  } catch (error) {
    console.error({ error, message, values })
    return message
  }
}
