import React, { FC, ComponentProps } from 'react'
import { DetailsDrawer } from 'components'
import { makeStyles, Theme, Hidden } from '@material-ui/core'
import { Paper } from 'tackl-material'
import { CloseHandle } from './components'

type Props = Omit<ComponentProps<typeof DetailsDrawer>, 'PaperProps' | 'BackdropProps' | 'classes'> & {
  scrollRefCallback?: (el: HTMLDivElement) => void
}

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  paper: {
    overflow: 'initial',
    backgroundColor: 'transparent',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    borderRadius: 'inherit',
    backgroundColor: palette.background.default,
    WebkitOverflowScrolling: 'touch',
  },
  backdrop: {
    [breakpoints.down('sm')]: {
      backgroundColor: palette.background.default,
    },
  },
}))

const DraggableDetailsDrawer: FC<Props> = ({ children, onClose, scrollRefCallback, ...props }) => {
  const classes = useStyles()

  return (
    <DetailsDrawer
      {...props}
      onClose={onClose}
      PaperProps={Paper.defaultProps}
      classes={{
        paper: classes.paper,
      }}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <Hidden
        mdUp
        implementation="css"
      >
        <CloseHandle onClose={onClose} />
      </Hidden>
      <Paper
        elevation={1}
        square={false}
        innerRef={scrollRefCallback}
        className={classes.content}
      >
        {children}
      </Paper>
    </DetailsDrawer>
  )
}

export default DraggableDetailsDrawer
