import React, { FC, ReactNode, useState, useCallback } from 'react'
import cls from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Info } from 'tackl-material/Icons'
import { CenteredBox, Button } from 'tackl-material'
import { Grow, IconButton, Backdrop } from '@material-ui/core'

type Props = {
  label?: ReactNode
  disabled?: boolean
  tooltipClassName?: string
}

const useStyles = makeStyles<Theme>(({ palette, zIndex }) => ({
  root: {
    position: 'relative',
  },
  iconContainer: {
    width: 25,
    height: 25,
    borderRadius: '150%',
    border: '1px solid rgba(0, 0, 0, .1)',
    backgroundColor: palette.neutral[300].main,
  },
  icon: {
    width: 5,
  },
  tooltip: {
    position: 'absolute',
    top: '115%',
  },
  alwaysVisible: {
    zIndex: zIndex.tooltip + 1,
  },
  backdrop: {
    zIndex: zIndex.tooltip,
    backgroundColor: 'transparent',
  },
}))

const InfoTooltip: FC<Props> = ({ children, disabled = false, label, tooltipClassName }) => {
  const classes = useStyles()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const close = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    setIsTooltipOpen(false)
  }, [])

  const toggle = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    setIsTooltipOpen((prevIsTooltipOpen) => !prevIsTooltipOpen)
  }, [])

  return (
    <div className={classes.root}>
      {
        label
          ? (
            <Button
              variant="text"
              onClick={toggle}
              disabled={disabled}
              startIcon={(
                <CenteredBox
                  component="span"
                  className={classes.iconContainer}
                >
                  <Info
                    fontSize="inherit"
                    className={classes.icon}
                  />
                </CenteredBox>
              )}
            >
              {label}
            </Button>
          ) : (
            <IconButton
              size="small"
              onClick={toggle}
              disabled={disabled}
              classes={{
                root: classes.iconContainer,
              }}
              className={classes.alwaysVisible}
            >
              <Info
                fontSize="inherit"
                className={classes.icon}
              />
            </IconButton>
          )
      }

      <Grow in={isTooltipOpen}>
        <div
          onClick={ev => ev.stopPropagation()}
          className={cls(tooltipClassName, classes.tooltip, classes.alwaysVisible)}
        >
          {children}
        </div>
      </Grow>

      <Backdrop
        onClick={close}
        open={isTooltipOpen}
        className={classes.backdrop}
      />
    </div>
  )
}

export default InfoTooltip
