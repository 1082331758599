import React, { FC } from 'react'
import cls from 'classnames'

import { Grid, ButtonBase, makeStyles, Theme, Box } from '@material-ui/core'
import Reply from '@material-ui/icons/Reply'

import { IUser } from 'protos'
import { Typography, Avatar, Link } from 'tackl-material'
import { Markdown, FormattedRelative } from 'components'

interface Props {
  incoming?: boolean
  user: IUser
  message: string
  timestamp: number
  onMention?: () => void
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(2, 3, 1, 2),
  },
  bubbleContainer: {
    marginLeft: theme.spacing(1),
  },
  bubble: {
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  incomingMessage: {
    backgroundColor: '#f5f5f5',
  },
  outgoingMessage: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
  },
  replyIconContainer: {
    borderRadius: 16,
    padding: 4,
    marginRight: -4,
    marginBottom: -4,
  },
  replyIcon: {
    width: 16,
    height: 16,
    color: '#aaa',
  },
}))

const GameChatMessage: FC<Props> = ({
  timestamp,
  incoming,
  onMention,
  message,
  user,
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.root}
    >
      <Link href={`/profile/${user.userId}`}>
        <Avatar src={user.photoUrl} />
      </Link>
      <Grid
        item
        xs
        className={classes.bubbleContainer}
      >
        <div
          className={cls(classes.bubble, {
            [classes.incomingMessage]: incoming,
            [classes.outgoingMessage]: !incoming,
          })}
        >
          <Grid container>
            <Grid
              item
              xs
            >
              <Typography variant="body2">
                <strong>
                  {user.nickname}
                </strong>
              </Typography>
            </Grid>
            {timestamp > 0 && (
              <Typography
                variant="caption"
                color="accent-200"
              >
                <FormattedRelative date={timestamp} />
              </Typography>
            )}
          </Grid>
          <Box mt={1}>
            <Grid
              container
              alignItems="flex-end"
            >
              <Grid
                item
                xs
              >
                <Typography variant="body2">
                  <Markdown>{message}</Markdown>
                </Typography>
              </Grid>
              {incoming && onMention && (
                <ButtonBase
                  className={classes.replyIconContainer}
                  onClick={onMention}
                >
                  <Reply className={classes.replyIcon} />
                </ButtonBase>
              )}
            </Grid>
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}

export default GameChatMessage
