import { ComponentProps, useMemo } from 'react'
import { Game } from 'protos'

import publicGroupWaitingIconUrl from './assets/PublicGroupWaiting.svg'
import publicGroupPendingIconUrl from './assets/PublicGroupUpcoming.svg'
import publicGroupLiveIconUrl from './assets/PublicGroupLive.svg'
import publicGroupWonIconUrl from './assets/PublicGroupWon.svg'
import publicGroupLostIconUrl from './assets/PublicGroupLost.svg'

interface Props extends ComponentProps<'img'> {
  state?: Game.State | null | undefined
}

function PublicGroupGameIndicator({ state, ...props }: Props) {
  const src = useMemo(() => {
    switch (state) {
      case Game.State.GS_PENDING:
      case Game.State.GS_UPCOMING:
        return publicGroupPendingIconUrl

      case Game.State.GS_RUNNING_LIVE:
        return publicGroupLiveIconUrl

      case Game.State.GS_FINISHED_WIN:
        return publicGroupWonIconUrl

      case Game.State.GS_FINISHED_LOSS:
        return publicGroupLostIconUrl

      default:
        return publicGroupWaitingIconUrl
    }
  }, [state])

  return (
    <img
      src={src}
      alt=""
      {...props}
    />
  )
}

export default PublicGroupGameIndicator
