import React, { createContext, useContext, useState, useCallback, FC } from 'react'
import { IBetSlip } from 'protos'
import { BetSlipDrawer } from 'components'

const Context = createContext({
  open: (betSlip: IBetSlip, betSlips?: IBetSlip[] | null) => {},
})

export const BetSlipDrawerContextProvider: FC = ({ children }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ betSlip, setBetSlip ] = useState<IBetSlip>()
  const [ betSlips, setBetSlips ] = useState<IBetSlip[] | null>()

  const open = useCallback((betSlip: IBetSlip, betSlips?: IBetSlip[] | null) => {
    setBetSlip(betSlip)
    setBetSlips(betSlips)
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <Context.Provider value={{ open }}>
      {children}
      <BetSlipDrawer
        open={isOpen}
        onClose={close}
        betSlip={betSlip}
        betSlips={betSlips}
      />
    </Context.Provider>
  )
}

export const useBetSlipDrawer = () => useContext(Context)
