import React, { ComponentProps as CP, FC } from 'react'
import cls from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Typography } from 'tackl-material'
import { IGame } from 'protos'
import { parseMarkdown } from 'lib'
import { Markdown } from 'components'

const useStyles = makeStyles({
  root: {
    maxHeight: 42,
    overflow: 'hidden',
  },
})

type Props = Omit<CP<typeof Typography>, 'title'> & {
  title: IGame['titleText']
}

const GameCardHeaderTitle: FC<Props> = ({ title, className, ...props }) => {
  const classes = useStyles()
  const titleString = title ? parseMarkdown(title) : ''
  const isMultiline = titleString.includes('<br>')

  return (
    <Typography
      noWrap={isMultiline}
      {...props}
      className={cls(classes.root, className)}
    >
      <Markdown>
        {title}
      </Markdown>
    </Typography>
  )
}

export default GameCardHeaderTitle
