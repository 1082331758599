import React, { FC, ComponentProps } from 'react'

import cls from 'classnames'
import { Theme, makeStyles } from '@material-ui/core'

import { Paper } from 'tackl-material'
import { IMatch, IPrediction } from 'protos'
import { isLive, isPredictionEditable, isUpcoming } from 'util/match'

import { MatchCardHeader, MatchCardFooter } from './components'

interface Props extends ComponentProps<typeof Paper> {
  match?: IMatch | null
  prediction?: IPrediction | null
}

const useStyles = makeStyles<Theme>({
  root: {
    height: 90,
  },
})

const MatchCard: FC<Props> = ({
  match,
  className,
  prediction,
  ...props
}) => {
  const classes = useStyles()

  const _isLive = isLive(match?.state)
  const _isPredictionMissing = !prediction?.predictedScore

  return (
    <Paper
      square={false}
      elevation={1}
      className={cls(className, classes.root)}
      {...props}
    >
      <MatchCardHeader
        isLive={_isLive}
        matchProgress={match?.progress ?? 0}
        homeTeamName={match?.homeTeam?.teamName}
        awayTeamName={match?.awayTeam?.teamName}
        homeScore={match?.currentScore?.homeScore}
        awayScore={match?.currentScore?.awayScore}
        isPredictionMissing={_isPredictionMissing}
        homeTeamColor={match?.homeTeam?.primaryColor}
        awayTeamColor={match?.awayTeam?.primaryColor}
        isEditable={isPredictionEditable(match?.state, prediction)}
      />

      <MatchCardFooter
        isLive={_isLive}
        matchClock={match?.clock ?? '0\''}
        isUpcoming={isUpcoming(match?.state)}
        matchStartTime={match?.startTime ?? 0}
        predictionValue={prediction?.value ?? 0}
        isPositive={(prediction?.value ?? 0) > 0}
        isPredictionMissing={_isPredictionMissing}
        homeScorePredicted={prediction?.predictedScore?.homeScore ?? 0}
        awayScorePredicted={prediction?.predictedScore?.awayScore ?? 0}
      />
    </Paper>
  )
}

export default MatchCard
