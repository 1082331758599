import React, { FC, ComponentProps, ComponentType, ReactChild } from 'react'
import cls from 'classnames'
import { Tabs as MuiTabs, makeStyles, Theme } from '@material-ui/core'

type Props = Omit<ComponentProps<typeof MuiTabs>, 'component'> & {
  collapse?: 'md'
  component?: ComponentType | (() => ReactChild)
}

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  flexContainer: {
    alignItems: 'center',
    overflow: 'visible !important',
  },
  collapseMd: {
    [breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}))

const Tabs: FC<Props> = ({ collapse, ...props }) => {
  const classes = useStyles()

  return (
    <MuiTabs
      {...props}
      classes={{
        flexContainer: cls(classes.flexContainer, {
          [classes.collapseMd]: collapse === 'md',
        }),
      }}
    />
  )
}

export default Tabs
