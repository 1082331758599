import React, { FC, useEffect, useLayoutEffect, useCallback } from 'react'
import useStayScrolled from 'react-stay-scrolled'

import { Grid, Typography, RootRef, Theme, makeStyles, useMediaQuery, useTheme, ClickAwayListener, Box } from '@material-ui/core'

import { GameChatComment } from 'services/chat'
import { FormattedMessage, DetailsDrawer } from 'components'
import { analyticsService } from 'services/global'
import { IGameMember, User } from 'protos'
import { Button } from 'tackl-material'

import inactiveChatIconUrl from './assets/chat_inactive.svg'
import { GameChatMessage, GameChatInput, GameChatBar } from './components'
import { height as chatBarHeight } from './components/GameChatBar/GameChatBar'

interface Props {
  active?: boolean
  members: IGameMember[]
  comments: GameChatComment[]
  analyticsOpenEventName?: string
  onSend?: (text: string) => void
  onAcceptInvitation?: () => void
  onDeclineInvitation?: () => void
}

const useStyles = makeStyles<Theme>(({ spacing, palette, transitions }) => ({
  root: {
    overflow: 'hidden',
    transition: transitions.create(['height'], {
      duration: transitions.duration.standard,
    }),
    backgroundColor: palette.common.white,
    boxShadow: '0 -7px 7px -7px rgba(0, 0, 0, 0.25)',
  },
  emptyIcon: {
    width: 48,
    height: 48,
    display: 'block',
    margin: `${spacing(6)}px auto ${spacing(2)}px`,
  },
  commentContainer: {
    width: '100%',
  },
  comments: {
    backgroundColor: palette.common.white,
  },
  drawerPaper: {
    borderRadius: 0,
    height: 'auto !important',
  },
}))

const GameChat: FC<Props> = ({
  active,
  comments,
  members,
  onSend,
  onAcceptInvitation,
  onDeclineInvitation,
  analyticsOpenEventName,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const calculateChatHeight = useCallback(() => {
    if (!onSend) {
      return 0
    } else if (isDesktop) {
      return window.innerHeight - 75
    } else {
      return window.innerHeight * 0.9 - 75
    }
  }, [isDesktop, onSend])

  const [open, setOpen] = React.useState(!onSend)
  const [chatHeight, setChatHeight] = React.useState(calculateChatHeight())
  const commentsRef = React.useRef<HTMLElement>(null) as React.MutableRefObject<HTMLElement>
  const { stayScrolled, scrollBottom } = useStayScrolled(commentsRef)
  const [text, setText] = React.useState('')

  const send = () => {
    onSend!(text)

    analyticsService.get().logEvent('ChatMessage_Sent')
    setText('')
  }

  const mention = (name: string) => {
    setText(`${text} @${name}`)
  }

  const setCommentsRef = useCallback((el: HTMLElement | null) => {
    // WORKAROUND: only set commentsRef if it not null
    if (el) {
      commentsRef.current = el
    }
  }, [])

  useEffect(() => {
    const onResize = () => {
      setChatHeight(calculateChatHeight())
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [calculateChatHeight])

  useLayoutEffect(() => {
    scrollBottom()
  }, [open, scrollBottom])

  useLayoutEffect(() => {
    stayScrolled()
  }, [stayScrolled, comments.length])

  useEffect(() => {
    if (open && analyticsOpenEventName) {
      analyticsService.get().logEvent(analyticsOpenEventName)
    }
  }, [open, analyticsOpenEventName])

  return (
    <>
      <DetailsDrawer
        open
        anchor="bottom"
        ModalProps={{
          hideBackdrop: true,
          disablePortal: true,
        }}
        variant={!open ? 'persistent' : 'temporary'}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            className={classes.root}
            style={{ height: !open ? chatBarHeight : (onSend ? chatHeight : 'auto') }}
          >
            <Grid
              container
              direction="column"
              style={{ height: onSend ? chatHeight : 'auto' }}
            >
              <GameChatBar
                open={open}
                active={active}
                onClick={() => setOpen(!open)}
                title={(
                  onSend
                    ? (
                      <>
                        <Typography
                          variant="body2"
                          display="inline"
                        >
                          <strong>
                            <FormattedMessage id="chat_title" />
                          </strong>
                        </Typography>
                        <Typography
                          variant="body2"
                          display="inline"
                        >
                          {' '}
                          ({comments.length})
                        </Typography>
                      </>
                    )
                    : (
                      <Typography
                        variant="body2"
                        display="inline"
                      >
                        <strong>
                          <FormattedMessage id="group_details_welcomemessage" />
                        </strong>
                      </Typography>
                    )
                )}
              />
              <RootRef rootRef={setCommentsRef}>
                <Grid
                  item
                  xs
                  className={classes.commentContainer}
                  style={{ overflowY: onSend && 'auto' }}
                >
                  {comments.length === 0 && (
                    <>
                      <img
                        src={inactiveChatIconUrl}
                        className={classes.emptyIcon}
                        alt="Chat bubble"
                      />
                      <Typography
                        variant="body2"
                        align="center"
                        color="textSecondary"
                      >
                        <FormattedMessage id="chat_empty_title" />
                        {' '}
                        <FormattedMessage id="chat_empty_message" />
                      </Typography>
                    </>
                  )}
                  <div className={classes.comments}>
                    {comments.map(({ from, msg, ts }, i) => {
                      const entry = members.find(({ user }) => Boolean(user && user.userId === from))
                      if (!entry || !entry.user) {
                        return null
                      }

                      const userName = entry.user.nickname!

                      return (
                        <GameChatMessage
                          key={i}
                          incoming={entry.user.relationship !== User.Relationship.REL_SELF}
                          user={entry.user!}
                          timestamp={ts}
                          message={msg}
                          onMention={onSend && (() => mention(userName))}
                        />
                      )
                    })}
                  </div>
                </Grid>
              </RootRef>
              {onSend
                ? (
                  <GameChatInput
                    value={text}
                    onChange={setText}
                    onSend={send}
                  />
                )
                : (
                  <Box m={2}>
                    <Grid container>
                      <Grid
                        item
                        xs
                      >
                        <Box mx={1}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={onDeclineInvitation}
                          >
                            <FormattedMessage id="game_action_decline" />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs
                      >
                        <Box mx={1}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="accent-50"
                            onClick={onAcceptInvitation}
                          >
                            <FormattedMessage id="game_action_acceptgroup" />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
            </Grid>
          </div>
        </ClickAwayListener>
      </DetailsDrawer>
      <div style={{ height: chatBarHeight }} />
    </>
  )
}

export default GameChat
