import React, { FC, useCallback, useRef, useState } from 'react'
import { NotificationDrawer } from 'components'
import { INotification } from 'protos'
import NotificationsContext from './NotificationsContext'

type Props = {
  confirmNotification: (notificationId: string) => Promise<void>
}

const NotificationsContextProvider: FC<Props> = ({ confirmNotification, children }) => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const notificationKeyRef = useRef(0)

  const addNotifications = useCallback((notifications?: INotification[] | null) => {
    setNotifications(state => notifications?.length ? [...state, ...notifications] : state)
  }, [])

  const [currentNotification, ...restOfTheNotifications] = notifications

  const nextNotification = useCallback(async (notificationId: INotification['notificationId']) => {
    if (notificationId) {
      await confirmNotification(notificationId)
    }

    notificationKeyRef.current++
    setNotifications(restOfTheNotifications)
  }, [confirmNotification, restOfTheNotifications])

  return (
    <NotificationsContext.Provider value={{ notifications, addNotifications }}>
      {children}

      {currentNotification && (
        <NotificationDrawer
          key={notificationKeyRef.current}
          open={!!currentNotification}
          onDismiss={nextNotification}
          initialNotification={currentNotification}
        />
      )}
    </NotificationsContext.Provider>
  )
}

export default NotificationsContextProvider
