import React from 'react'
import cls from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import MuiPaper, { PaperProps as MuiPaperProps } from '@material-ui/core/Paper'
import { Color } from '../propTypes'
import { findPaperColor } from '../util'

interface PaperProps extends MuiPaperProps {
  color?: Color
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  colored: ({ color }: PaperProps) => findPaperColor(palette, color),
}))

const Paper: React.FC<PaperProps> = props => {
  const classes = useStyles(props)
  const { color, className, ...paperProps } = props

  return (
    <MuiPaper
      {...paperProps}
      className={cls(className, classes.colored)}
    />
  )
}

Paper.defaultProps = {
  elevation: 0,
  square: true,
}

export default Paper
