import React, { FC, useEffect, useCallback, useRef, useReducer } from 'react'
import { SDKStatus, NotificationPermission, OneSignalService, OneSignal } from 'services/onesignal'
import config from 'services/config'

import OneSignalContext, { initialContext, Context } from './OneSignalContext'

interface Props {
  oneSignalService: OneSignalService
}

type Action =
  | { type: 'setSDKStatus', payload: SDKStatus }
  | { type: 'setNotificationPermission', payload: NotificationPermission }
  | {
    type: 'setSubscription'
    payload: {
      isSubscribed: boolean
      userId: string | null
      unsubscribe: () => void
      subscribe: () => void
    }
  }

const reducer = (state: Context, action: Action): Context => {
  switch (action.type) {
    case 'setSDKStatus':
      return {
        ...state,
        sdkStatus: action.payload,
      }
    case 'setSubscription':
      return {
        ...state,
        isSubscribed: action.payload.isSubscribed,
        userId: action.payload.userId,
        unsubscribe: action.payload.unsubscribe,
        subscribe: action.payload.subscribe,
      }
    case 'setNotificationPermission':
      return {
        ...state,
        notificationPermission: action.payload,
        isBlockedTemporarily: state.notificationPermission === 'default' && action.payload === 'default',
      }

    default:
      return state
  }
}

const OneSignalContextProvider: FC<Props> = ({ oneSignalService, children }) => {
  const [state, dispatch] = useReducer(reducer, initialContext)

  const initialize = useCallback(async () => {
    oneSignalService.initialize()

    const timeout = new Promise<OneSignal>((_, reject) => {
      window.setTimeout(() => reject(new Error('OneSignal SDK timeout out.')), 3_000)
    })

    try {
      const os = await Promise.race([oneSignalService.getOneSignal(), timeout])
      if (!os.isPushNotificationsSupported()) {
        throw new Error('Unsupported platform.')
      }

      await os.init({
        appId: config.onesignal.appId,
        notifyButton: {
          enable: false,
        },
      })
      dispatch({ type: 'setSDKStatus', payload: 'loaded' })
    } catch (err) {
      dispatch({ type: 'setSDKStatus', payload: 'failed' })
    }
  }, [oneSignalService])

  useEffect(() => { initialize() }, [initialize])

  const savedSubscriptionChange = useRef(async () => {
    const os = await oneSignalService.getOneSignal()

    const [isPushEnabled, isOptedOut, userId] = await Promise.all([
      os.isPushNotificationsEnabled(),
      os.isOptedOut(),
      os.getUserId(),
    ] as const)

    dispatch({
      type: 'setSubscription',
      payload: {
        userId,
        isSubscribed: isPushEnabled,
        unsubscribe: () => os.setSubscription(false),
        subscribe: isOptedOut
          ? () => os.setSubscription(true)
          : () => os.registerForPushNotifications().then(() => os.registerForPushNotifications()),
      },
    })
  })

  const subscriptionChangeListener = useCallback(() => {
    savedSubscriptionChange.current!()
  }, [])

  const notificationPermissionChangeListener = useCallback(async ({ to }) => {
    if (to === 'granted') {
      const os = await oneSignalService.getOneSignal()
      await os.registerForPushNotifications()
    }

    dispatch({
      type: 'setNotificationPermission',
      payload: to,
    })
  }, [oneSignalService])

  const mountListeners = useCallback(async () => {
    const os = await oneSignalService.getOneSignal()

    if (!os.isPushNotificationsSupported()) {
      return
    }

    subscriptionChangeListener()
    dispatch({
      type: 'setNotificationPermission',
      payload: await os.getNotificationPermission(),
    })

    os.on('subscriptionChange', subscriptionChangeListener)
    os.on('notificationPermissionChange', notificationPermissionChangeListener)
  }, [oneSignalService, subscriptionChangeListener, notificationPermissionChangeListener])

  const unmountListeners = useCallback(async () => {
    const os = await oneSignalService.getOneSignal()

    if (!os.isPushNotificationsSupported()) {
      return
    }

    os.off('subscriptionChange', subscriptionChangeListener)
    os.off('notificationPermissionChange', notificationPermissionChangeListener)
  }, [oneSignalService, subscriptionChangeListener, notificationPermissionChangeListener])

  useEffect(() => {
    mountListeners()
    return () => { unmountListeners() }
  }, [mountListeners, unmountListeners])

  return (
    <OneSignalContext.Provider value={state}>
      {children}
    </OneSignalContext.Provider>
  )
}

export default OneSignalContextProvider
