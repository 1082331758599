import React, { FC, ComponentProps } from 'react'

import cls from 'classnames'
import { Theme, makeStyles } from '@material-ui/core'

import { Paper } from 'tackl-material'
import { IMatch, IPrediction } from 'protos'
import { isCancelled, isFinished, isLive, isPredictionEditable, isUpcoming } from 'util/match'

import { LotteryMatchCardHeader, LotteryMatchCardFooter } from './components'

interface Props extends ComponentProps<typeof Paper> {
  match?: IMatch | null
  prediction?: IPrediction | null
}

const useStyles = makeStyles<Theme>({
  root: {
    height: 115,
  },
})

const LotteryMatchCard: FC<Props> = ({
  match,
  className,
  prediction,
  ...props
}) => {
  const classes = useStyles()

  const _isLive = isLive(match?.state)
  const _isCancelled = isCancelled(match?.state)
  const _isPredictionMissing = !prediction?.predictedScore

  const _isPositive =
    match?.currentScore?.homeScore === prediction?.predictedScore?.homeScore &&
    match?.currentScore?.awayScore === prediction?.predictedScore?.awayScore

  return (
    <Paper
      square={false}
      elevation={1}
      className={cls(className, classes.root)}
      {...props}
    >
      <LotteryMatchCardHeader
        isLive={_isLive}
        prediction={prediction}
        betOffer={match?.betOffer}
        isCancelled={_isCancelled}
        matchProgress={match?.progress ?? 0}
        homeTeamName={match?.homeTeam?.teamName}
        awayTeamName={match?.awayTeam?.teamName}
        homeScore={match?.currentScore?.homeScore}
        awayScore={match?.currentScore?.awayScore}
        homeTeamColor={match?.homeTeam?.primaryColor}
        awayTeamColor={match?.awayTeam?.primaryColor}
      />

      <LotteryMatchCardFooter
        isLive={_isLive}
        isPositive={_isPositive}
        isCancelled={_isCancelled}
        matchClock={match?.clock ?? '0\''}
        isUpcoming={isUpcoming(match?.state)}
        isFinished={isFinished(match?.state)}
        matchStartTime={match?.startTime ?? 0}
        predictionValue={prediction?.value ?? 0}
        isPredictionMissing={_isPredictionMissing}
        isEditable={isPredictionEditable(match?.state, prediction)}
        homeScorePredicted={prediction?.predictedScore?.homeScore ?? 0}
        awayScorePredicted={prediction?.predictedScore?.awayScore ?? 0}
      />
    </Paper>
  )
}

export default LotteryMatchCard
