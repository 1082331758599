import React, { FC, ComponentProps } from 'react'
import cls from 'classnames'
import { makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { BottomDrawer } from 'tackl-material'

type Props = ComponentProps<typeof BottomDrawer>

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  paper: {
    maxHeight: '100vh',
    height: '-webkit-fill-available',
    backgroundColor: palette.background.default,
    [breakpoints.up('md')]: {
      width: '35%',
      left: 'auto',
      right: 0,
      minWidth: 375,
      maxHeight: 'initial',
    },
  },
}))

const DetailsDrawer: FC<Props> = ({ classes, ...props }) => {
  const theme = useTheme()
  const localClasses = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <BottomDrawer
      anchor={isDesktop ? 'right' : 'bottom'}
      variant="temporary"
      {...props}
      classes={{
        ...classes,
        paper: cls(
          classes && classes.paper,
          localClasses.paper,
        ),
      }}
    />
  )
}

export default DetailsDrawer
