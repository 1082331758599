import React, { ComponentProps, FC } from 'react'

import cls from 'classnames'
import { Grid, makeStyles, Theme } from '@material-ui/core'

import { useIntl } from 'hooks'
import { IBetSlip } from 'protos'
import { FormattedMessage } from 'components'
import { Paper, Box, Typography, Divider } from 'tackl-material'
import tipicoDeLogoUrl from 'assets/tipico_de.svg'

interface Props extends Omit<ComponentProps<typeof Paper>, 'title'> {
  title: IBetSlip['slipTitle']
  subtitle: IBetSlip['slipSubtitle']
  timestamp: IBetSlip['slipTimestamp']
  sponsorLogoUrl: IBetSlip['sponsorLogoUrl']
}

const useStyles = makeStyles<Theme>(({ palette, spacing }) => ({
  root: {
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  blackDivider: {
    margin: spacing(0, 2),
    backgroundColor: palette.common.black,
  },
}))

const BetSlipHeader: FC<Props> = ({ title, subtitle, timestamp, className, ...props }) => {
  const { d } = useIntl()
  const classes = useStyles()

  const sponsorLogoUrl = tipicoDeLogoUrl

  return (
    <Paper
      square={false}
      className={cls(className, classes.root)}
      {...props}
    >
      <Box
        p={2}
        pb={1}
      >
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
        >
          <Grid
            xs
            item
          >
            <Typography variant="h3">
              {title}
            </Typography>
            <Typography
              alpha={0.5}
              variant="subtitle2"
            >
              {subtitle || d(Number(timestamp || 0) * 1000)}
            </Typography>
          </Grid>
          {sponsorLogoUrl && (
            <Grid
              item
              container
              direction="column"
              alignItems="flex-end"
              style={{ width: 'auto' }}
            >
              <Grid item>
                <Typography
                  alpha={0.5}
                  variant="subtitle2"
                >
                  <FormattedMessage id="web_bild_advertisement_extended_label" />
                </Typography>
              </Grid>
              <Grid item>
                <img
                  height={18}
                  alt="sponsor logo"
                  src={sponsorLogoUrl}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box mt={.5}>
          <Typography
            alpha={.7}
            color="inherit"
            variant="subtitle2"
          >
            <FormattedMessage id="web_gambling_legal_text" />
          </Typography>
        </Box>
      </Box>
      <Divider className={classes.blackDivider} />
    </Paper>
  )
}

export default BetSlipHeader
