import React, { FC } from 'react'
import { makeStyles, Theme, fade } from '@material-ui/core/styles'
import MuiDivider, {
  DividerProps as MuiDividerProps,
} from '@material-ui/core/Divider'

interface DashedDividerProps extends MuiDividerProps {}

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    borderTop: `1px dashed ${fade(palette.common.black, .3)}`,
  },
  light: {
    borderColor: fade(palette.common.black, .1),
  },
}))

const DashedDivider: FC<DashedDividerProps> = (props) => {
  const classes = useStyles()

  return (
    <MuiDivider
      {...props}
      classes={classes}
    />
)
}

export default DashedDivider
