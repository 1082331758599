const parseLangs = (langs: string = '') =>
  langs.split(',').map((v) => v.trim())

const config = {
  appName: process.env.REACT_APP_APP_NAME || process.env.REACT_APP_TENANT_ID || 'tackl',
  tenantId: process.env.REACT_APP_TENANT_ID || process.env.STORYBOOK_TENANT_ID || 'tackl',
  allowedLangs: parseLangs(process.env.REACT_APP_ALLOWED_LANGS),
  firebase: {
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  },
  onesignal: {
    appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '',
  },
  gamelogic: {
    baseUrl: process.env.REACT_APP_GAMELOGIC_BASE_URL || '',
  },
  units: {
    credit: '¢',
  },
  forcedIframeOnlyUrl: process.env.REACT_APP_FORCED_IFRAME_ONLY_URL,
  landingUrl: process.env.REACT_APP_LANDING_URL || 'https://tackl.me',
  landingUrlAdjust: process.env.REACT_APP_LANDING_URL_ADJUST || 'https://app.adjust.com/5uqju95?fallback=https%3A%2F%2Ftackl.me',
}

type Config = typeof config & { tenantId: 'tackl' | 'tippstar' | 'bild' }

export default config as Config
