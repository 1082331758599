import React, { FC } from 'react'
import cls from 'classnames'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MatchTimeline } from 'protos'
import { Box, Typography } from 'tackl-material'
import TimelineListItem from './TimelineListItem'

interface Props {
  event: MatchTimeline.IScoreChangeEvent
}

interface SideComponentProps {
  side: 'left' | 'right'
  event: Props['event']
}

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  left: {
    paddingLeft: spacing(1),
    paddingRight: spacing(2),
  },
  right: {
    paddingLeft: spacing(2),
    paddingRight: spacing(1),
  },
}))

const SideComponent: FC<SideComponentProps> = ({ event, side }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      minWidth={0}
      flexDirection={side === 'right' ? 'row' : 'row-reverse'}
    >
      <Typography
        noWrap
        className={cls({
          [classes.left]: side === 'left',
          [classes.right]: side === 'right',
        })}
      >
        <strong>{event.player}</strong>
      </Typography>

      <Typography alpha={0.3}>
        <strong>{event.newScore!.homeScore}:{event.newScore!.awayScore}</strong>
      </Typography>
    </Box>
  )
}

const ScoreChangeListItem: FC<Props> = ({ event }) => {
  return (
    <TimelineListItem
      event={event}
      leftNode={(
        <SideComponent
          side="left"
          event={event}
        />
      )}
      rightNode={(
        <SideComponent
          side="right"
          event={event}
        />
      )}
    />
  )
}

export default ScoreChangeListItem
