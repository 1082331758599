import React, { FC } from 'react'
import { useIntl } from 'hooks'
import { Currency, FormattedMessage } from 'components'
import { OpponentHighlight, ICurrency } from 'protos'

interface Props {
  winRatio?: number | null
  winCount?: number | null
  highlight?: OpponentHighlight | null
  gamesCount?: number | null
  drinkBalance?: ICurrency | null
  creditBalance?: ICurrency | null
}

const Highlight: FC<Props> = ({
  winRatio,
  winCount,
  highlight,
  gamesCount,
  drinkBalance,
  creditBalance,
}) => {
  const { language } = useIntl()

  const displayDefault = highlight === OpponentHighlight.OPH_NONE
  const displayCreditBalance = displayDefault || highlight === OpponentHighlight.OPH_CREDIT_BALANCE
  const displayDrinkBalance = highlight === OpponentHighlight.OPH_DRINK_BALANCE
  const displayGamesCount = highlight === OpponentHighlight.OPH_GAMES_COUNT
  const displayGamesCountKey = Number(gamesCount) <= 1 ? 'profile_games_singular' : 'profile_games_plural'
  const displayWinCount = highlight === OpponentHighlight.OPH_WIN_COUNT
  const displayWinCountKey = Number(winCount) <= 1 ? 'profile_games_singular' : 'profile_games_plural'
  const displayWinRatio = highlight === OpponentHighlight.OPH_WIN_RATIO

  if (displayCreditBalance) {
    return (
      <Currency {...creditBalance} />
    )
  }

  if (displayDrinkBalance) {
    return (
      <Currency {...drinkBalance} />
    )
  }

  if (displayGamesCount) {
    return (
      <>
        <span>{Number(gamesCount).toLocaleString(language)} </span>
        <FormattedMessage id={displayGamesCountKey} />
      </>
    )
  }

  if (displayWinCount) {
    return (
      <>
        <span>{Number(winCount).toLocaleString(language)} </span>
        <FormattedMessage id={displayWinCountKey} />
      </>
    )
  }

  if (displayWinRatio) {
    return (
      <>
        {`${Math.floor(Number(winRatio) * 100)}%`}
      </>
    )
  }

  return null
}

export default Highlight
