import React, { FC, ComponentProps } from 'react'

import cls from 'classnames'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Box, Typography } from 'tackl-material'
import { Checkmark, Close, Edit } from 'tackl-material/Icons'
import { FormattedMessage, FormattedDate } from 'components'

type Props = ComponentProps<typeof Box> & {
  isLive: boolean
  isEditable: boolean
  isUpcoming: boolean
  isPositive: boolean
  isFinished: boolean
  isCancelled: boolean
  matchClock?: string | null
  matchStartTime?: number | Long
  predictionValue?: number | null
  homeScorePredicted?: number | null
  awayScorePredicted?: number | null
  isPredictionMissing: boolean
}

const useStyles = makeStyles<Theme, Pick<Props, 'isEditable'>>(({ spacing, palette, shape }) => ({
  root: {
    height: 35,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'stretch',
    borderTop: `1px solid ${palette.divider}`,
    borderBottomLeftRadius: shape.borderRadius,
    borderBottomRightRadius: shape.borderRadius,
  },
  column: ({ isEditable }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${spacing(1)}px`,
    minWidth: isEditable ? 80 : 40,
  }),
  point: {
    backgroundColor: palette.neutral[200].main,
    borderLeft: `1px solid ${palette.divider}`,
    '&$positive': {
      color: palette.accent[50].main,
      backgroundColor: palette.accent[50].light,
      borderLeft: `2px solid ${palette.accent[50].main}`,
    },
    '&$negative': {
      color: palette.error.main,
      backgroundColor: palette.error.light,
      borderLeft: `2px solid ${palette.error.main}`,
    },
  },
  info: {
    flex: 1,
  },
  editIcon: {
    fontSize: 14,
    marginRight: spacing(.5),
  },
  editLabel: {
    lineHeight: '1',
    fontWeight: 'bold',
  },
  positive: {},
  negative: {},
}))

const LotteryMatchCardFooter: FC<Props> = ({
  isLive,
  className,
  isEditable,
  isPositive,
  isUpcoming,
  isFinished,
  isCancelled,
  matchClock = '0\'',
  matchStartTime = 0,
  predictionValue = 0,
  homeScorePredicted = 0,
  awayScorePredicted = 0,
  isPredictionMissing,
  ...props
}) => {
  const classes = useStyles({ isEditable })

  if (isCancelled) {
    return (
      <Box
        className={cls(className, classes.root)}
        {...props}
      />
    )
  }

  return (
    <Box
      className={cls(className, classes.root)}
      {...props}
    >
      <Box className={cls(classes.column)}>
        {(isUpcoming || isFinished) && (
          <Typography
            alpha={0.5}
            variant="body2"
          >
            <FormattedDate
              timestamp={Number(matchStartTime) * 1000}
              options={{
                hour: 'numeric',
                minute: 'numeric',
              }}
            />
          </Typography>
        )}

        {isLive && (
          <Typography
            variant="body2"
            color="accent-100"
          >
            <strong>
              {matchClock}
            </strong>
          </Typography>
        )}
      </Box>

      <Box className={cls(classes.column, classes.info)}>
        <Typography
          noWrap
          variant="body2"
        >
          {isPredictionMissing && (
            <FormattedMessage
              id="match_footer_missingprediction"
            />
          )}

          {!isPredictionMissing && (
            <FormattedMessage
              id="match_footer_prediction_format"
              values={[Number(homeScorePredicted), Number(awayScorePredicted)]}
            />
          )}
        </Typography>
      </Box>

      <Box
        className={cls(
          classes.column,
          classes.point,
          {
            [classes.positive]: !isUpcoming && isPositive,
            [classes.negative]: !isUpcoming && !isPositive,
          },
        )}
      >
        {
          isEditable && (
            <>
              <Edit
                color="inherit"
                className={classes.editIcon}
              />
              <Typography
                variant="body2"
                className={classes.editLabel}
              >
                <FormattedMessage id="web_bild_edit" />
              </Typography>
            </>
          )
        }
        {
          !isUpcoming && isPositive && (
            <Checkmark
              color="inherit"
              fontSize="inherit"
            />
          )
        }
        {
          !isUpcoming && !isPositive && (
            <Close
              color="inherit"
              fontSize="inherit"
            />
          )
        }
      </Box>
    </Box>
  )
}

export default LotteryMatchCardFooter
