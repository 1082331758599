import React from 'react'
import MuiBox, { BoxProps as MuiBoxProps } from '@material-ui/core/Box'

const CenteredBox: React.FC<MuiBoxProps> = props => (
  <MuiBox
    alignItems="center"
    justifyContent="center"
    {...props}
    display="flex"
  />
)

export default CenteredBox
