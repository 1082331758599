import React, { FC, useState, useCallback } from 'react'
import { Game, Match} from 'protos'
import useInterval from '@use-it/interval'
import { useSubmit } from 'hooks'
import { MatchDetailsDrawer } from 'components'
import { matchesGetTimeline } from 'services/gamelogic'
import MatchDetailsDrawerContext from './MatchDetailsDrawerContext'

const emptyMatch = Match.create({
  state: Match.State.MS_UPCOMING,
  homeTeam: { teamName: '-' },
  awayTeam: { teamName: '-' },
})

const MatchDetailsDrawerContextProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [matchId, setMatchId] = useState<string | undefined>()
  const [onClose, setOnClose] = useState<() => void | undefined>()
  const [gameType, setGameType] = useState<Game.GameType | null | undefined>()

  const { response, submit, reset, isSubmitted } = useSubmit(matchesGetTimeline)

  const handleClose = useCallback(() => {
    setIsOpen(false)
    onClose && onClose()
  }, [onClose])

  const onEntered = useCallback(() => {
    submit({ matchId })
  }, [matchId, submit])

  const onEntering = useCallback(() => {
    reset()
  }, [reset])

  useInterval(() => {
    if (isOpen) {
      submit({ matchId })
    }
  }, 30000)

  const match = (response && response.timeline!.match) || emptyMatch
  const timeline = (response && response.timeline) || undefined

  return (
    <MatchDetailsDrawerContext.Provider
      value={{
        setIsOpen,
        setMatchId,
        setOnClose,
        setGameType,
      }}
    >
      {children}
      <MatchDetailsDrawer
        open={isOpen}
        match={match}
        gameType={gameType}
        timeline={timeline}
        onClose={handleClose}
        isTimelineLoading={!isSubmitted}
        SlideProps={{ onEntered, onEntering }}
      />
    </MatchDetailsDrawerContext.Provider>
  )
}

export default MatchDetailsDrawerContextProvider
