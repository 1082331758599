import React, { FC } from 'react'

import { Game } from 'protos'

import { Props } from './propTypes'
import FriendGameCardHeader from './FriendGameCardHeader'
import JackpotGameCardHeader from './JackpotGameCardHeader'
import LotteryGameCardHeader from './LotteryGameCardHeader'
import PublicGroupGameCardHeader from './PublicGroupGameCardHeader'

const GameCardHeader: FC<Props> = (props) => {
  switch(props.game.gameType) {
    case Game.GameType.GT_PUBLIC_GROUP:
      return <PublicGroupGameCardHeader {...props} />
    case Game.GameType.GT_LOTTERY:
      return <LotteryGameCardHeader {...props} />
    case Game.GameType.GT_PRIVATE_HOSTED:
    case Game.GameType.GT_PRIVATE_INVITED:
      return <FriendGameCardHeader {...props} />
    default:
      return <JackpotGameCardHeader {...props} />
  }
}

export default GameCardHeader
