import 'firebase/analytics'
import config from 'services/config'
import firebase from '../firebase'

export type AnalyticsService = Pick<ReturnType<typeof firebase.analytics>, 'logEvent' | 'setCurrentScreen'>

let analyticsService: AnalyticsService = {
  logEvent: (...args: any[]) => console.log('[analytics] logEvent', ...args),
  setCurrentScreen: (...args: any[]) => console.log('[analytics] setCurrentScreen', ...args),
}

try {
  analyticsService = firebase.analytics()
} catch {
  // no-op
}

// NOTE: Disable to send automatic page_view events on initial page load
if ((window as any).gtag) {
  (window as any).gtag('config', config.firebase.measurementId, {
    'send_page_view': false,
  })
}

export default analyticsService
