import React, { FC, useState, useEffect, useCallback, useRef, useContext } from 'react'
import { sessionsLogin, RpcError } from 'services/gamelogic'
import { analyticsService, authService, identityToken, lastStatusResponseTimestamp } from 'services/global'
import { AuthUserInfo } from 'services/auth'
import { UserProfile } from 'protos'
import { useIntl, useOneSignal } from 'hooks'
import AuthContext from './AuthContext'

interface State {
  authError?: any
  sessionsLoginError?: RpcError | null
  userInfo: AuthUserInfo | null
  isAuthStateKnown: boolean
  referralParams?: UserProfile.IReferralParameters
}

const AuthContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<State>({
    userInfo: null,
    isAuthStateKnown: false,
  })

  const { sdkStatus, userId: onesignalPlayerId } = useOneSignal()
  const { language } = useIntl()

  const isAuthInitialized = useRef(false)
  const initializeAuthService = useCallback(async () => {
    try {
      await authService.get().initialize()
    } finally {
      isAuthInitialized.current = true
    }
  }, [])

  useEffect(() => {
    if (sdkStatus === 'pending') {
      return
    }

    const subscription = authService.get().onAuthStateChanged(async (userInfo, authError) => {
      let sessionsLoginError = null
      let referralParams = undefined

      const isLoggedIn = !!userInfo

      if (isLoggedIn) {
        try {
          const result = await sessionsLogin({ onesignalPlayerId }, language)

          if (result.identityToken) {
            identityToken.set(result.identityToken)
          }

          if (result.result !== 1) {
            throw new RpcError(
              undefined,
              result.errorTitle,
              result.result,
              result.errorMessage,
            )
          }

          referralParams = result.ownProfile?.referral || undefined

          analyticsService.get().logEvent('Login_Successful', {
            Type: authService.get().getProvider(),
          })
        } catch (loginError) {
          sessionsLoginError = loginError
        }
      } else {
        lastStatusResponseTimestamp.set(0)
        identityToken.set(undefined)
      }

      setState({
        authError,
        sessionsLoginError,
        userInfo,
        isAuthStateKnown: true,
        referralParams,
      })
    })

    if (!isAuthInitialized.current) {
      initializeAuthService()
    }

    return subscription
  }, [onesignalPlayerId, sdkStatus, initializeAuthService])

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider

export const useAuth = () => useContext(AuthContext)
