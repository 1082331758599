import React, { FC, ComponentProps } from 'react'
import cls from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Currency } from 'components'
import { Paper } from 'tackl-material'
import config from 'services/config'

type Props = Omit<ComponentProps<typeof Paper>, 'variant'> & {
  unit?: string | null
  amount?: number | Long | null
  variant?: 'neutral' | 'positive' | 'negative'
  fullWidth?: boolean
}

const useStyles = makeStyles<Theme>(({ typography, palette, customComponents }) => ({
  root: {
    fontFamily: typography.fontFamily,
    color: palette.primary.main,
    width: '100%',
    display: 'inline-block',
    fontSize: 0,
    lineHeight: 1,
  },
  fixedSize: {
    width: 200,
    height: 102,
  },
  backgroundNegative: {
    fill: 'tomato',
  },
  backgroundNeutral: {
    fill: 'aqua',
  },
  backgroundPositive: {
    fill: 'green',
  },
  ...customComponents.CreditBillLandscape,
}))

const CreditBillLandscape: FC<Props> = ({ amount = 0, unit = config.units.credit, fullWidth, className, variant, ...props }) => {
  const classes = useStyles()
  const displayedAmount = Number(amount) <= 0 ? String(amount) : `+${amount}`

  const isNegative = (!variant && Number(amount) < 0) || variant === 'negative'
  const isNeutral = (!variant && Number(amount) === 0) || variant === 'neutral'
  const isPositive = (!variant && Number(amount) > 0) || variant === 'positive'

  return (
    <Paper
      {...props}
      className={cls(className, classes.root, { [classes.fixedSize]: !fullWidth })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 200 102"
      >
        <g
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M0 0h200v101.8182H0V0z"
            className={cls({
              [classes.backgroundNegative]: isNegative,
              [classes.backgroundNeutral]: isNeutral,
              [classes.backgroundPositive]: isPositive,
            })}
          />
          <path
            fill="#000"
            d="M139.6364-2.9091H200v123.6364h-60.3636z"
            opacity=".25"
            transform="matrix(1 0 0 -1 0 117.8182)"
          />
          <path
            fill="#FFF"
            d="M0-.7273h48.7273v103.2727H0z"
            transform="matrix(1 0 0 -1 0 101.8182)"
          />
          <g>
            <path
              fill="#000"
              opacity=".1"
              d="M173.5547 14.838c2.052-2.7714 5.4154-4.5785 9.2156-4.5785 6.2497 0 11.3167 4.8847 11.3167 10.9079 0 1.7781-.4413 3.4569-1.2241 4.9401l6.5984 10.8232 1.6776-.4202-3.6608-10.8942a8.8017 8.8017 0 0 1 2.3065-1.3314c4.4988-1.7723 9.6319.3083 11.4704 4.647l16.2496 34.9457 5.7905 4.3318-24.9006 30.9462-5.7905-4.3284-13.6697-1.8068c-3.091-.3427-6.1193-1.4795-8.7564-3.4482l-27.7986-20.791c-7.2752-5.4393-8.9136-15.2862-3.9985-22.6012a8.322 8.322 0 0 1 .8272-1.2333L172.683 15.773a8.4932 8.4932 0 0 1 .8718-.935zM87.3855 26.1062c-.7822-1.483-1.2231-3.1613-1.2231-4.9389 0-6.0232 5.0651-10.908 11.3149-10.908 3.8015 0 7.1648 1.8075 9.2164 4.579.316.2918.6069.6045.871.9345l23.4766 29.1738c.3163.3945.595.8078.8255 1.2333 4.9168 7.315 3.2785 17.1619-3.9968 22.6012l-27.7985 20.791c-2.6371 1.9687-5.6655 3.1055-8.7564 3.4482l-13.6697 1.8068-5.7906 4.3284-24.9005-30.9462 5.7905-4.3318 16.2496-34.9457c1.8367-4.3387 6.9698-6.4193 11.4703-4.647a8.8307 8.8307 0 0 1 2.3066 1.3314l-3.6626 10.8942 1.6776.4202 6.5992-10.8244zm-61.051 62.7856c-1.4133-1.7569-1.0899-4.2836.7236-5.6564l16.4193-12.427 24.9006 30.948-16.1567 12.2323c-1.9492 1.4761-4.7721 1.1403-6.2908-.7475L26.3345 88.892zm227.5786 0l-19.5942 24.3494c-1.5205 1.8878-4.3416 2.2236-6.2908.7475l-16.1585-12.2324 24.9006-30.948 16.4193 12.4271c1.8135 1.3728 2.1368 3.8995.7236 5.6564z"
            />
            <path
              stroke="#FFF"
              strokeWidth="3.6364"
              d="M17.091 1.8182h181.0908V100H17.091z"
            />
            <path
              stroke="#000"
              opacity=".15"
              strokeWidth="1.5"
              strokeLinejoin="round"
              strokeDasharray="2,1.5"
              d="M140.3636-10.5455v132.3637"
            />
            <g fill="#000">
              <path d="M181.1334 9.9955c.2424-.3275.6398-.541 1.0888-.541.7383 0 1.337.5771 1.337 1.2887 0 .2101-.0522.4084-.1447.5837l.7796 1.2787.1982-.0497-.4325-1.287a1.0399 1.0399 0 0 1 .2725-.1573c.5315-.2094 1.138.0364 1.3551.549l1.9198 4.1286.6841.5117-2.9418 3.6561-.6841-.5114-1.615-.2134a2.114 2.114 0 0 1-1.0345-.4074l-3.2842-2.4563c-.8596-.6426-1.0531-1.806-.4724-2.6702a.9832.9832 0 0 1 .0977-.1457l2.7734-3.4467c.0312-.039.0656-.076.103-.1104zm-10.1803 1.3312a1.2464 1.2464 0 0 1-.1445-.5835c0-.7116.5984-1.2887 1.3368-1.2887.449 0 .8464.2136 1.0888.541a1.005 1.005 0 0 1 .103.1104l2.7735 3.4467a.9816.9816 0 0 1 .0975.1457c.581.8642.3874 2.0276-.4721 2.6702l-3.2843 2.4563a2.114 2.114 0 0 1-1.0345.4074l-1.615.2134-.684.5114-2.9419-3.656.6841-.5118 1.9198-4.1286c.217-.5126.8234-.7584 1.3551-.549.1011.0394.1928.0934.2725.1573l-.4327 1.287.1982.0497.7797-1.2789zm-7.2128 7.4177c-.167-.2075-.1287-.506.0855-.6682l1.9398-1.4682 2.9419 3.6563-1.9088 1.4452c-.2303.1744-.5638.1347-.7432-.0884l-2.3152-2.8767zm26.8869 0l-2.315 2.8767c-.1796.223-.5129.2628-.7432.0884l-1.909-1.4452 2.9419-3.6563 1.9398 1.4682c.2142.1621.2524.4607.0855.6682z" />
              <text
                x={13}
                y={36}
                fontSize={36}
                transform="translate(0 4)"
              >
                <Currency
                  amount={<tspan fontWeight="bold">{displayedAmount}</tspan>}
                  unit={<tspan fontWeight="normal">{unit}</tspan>}
                />
              </text>
              <text
                fontSize={13}
                fontWeight="bold"
                textAnchor="end"
                transform="translate(-10 0)"
              >
                <tspan
                  y={66}
                  x="100%"
                >
                  <Currency amount={displayedAmount} />
                </tspan>
                <tspan
                  y={78}
                  x="100%"
                  fontSize={9}
                >
                  tackl
                </tspan>
                <tspan
                  y={88}
                  x="100%"
                  fontSize={9}
                  fontWeight="normal"
                >
                  credits
                </tspan>
              </text>
              <text
                opacity=".5"
                fontSize={5}
                transform="rotate(-90 7 72)"
              >
                <tspan
                  x={-18}
                  y={72}
                  fontWeight="bold"
                >
                  <tspan fontWeight="bold">tackl</tspan> deine Freunde
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </Paper>
  )
}

CreditBillLandscape.defaultProps = {
  elevation: 1,
}

export default CreditBillLandscape
