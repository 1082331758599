import firebase from 'firebase/app'
import config from 'services/config'

const getDefaultFirebaseApp = () => {
  if (firebase.apps.length) {
    return firebase.app()
  } else {
    return firebase.initializeApp(config.firebase)
  }
}

export default getDefaultFirebaseApp()
