import React, { FC, ComponentProps } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Trophy: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon
    width="46"
    height="50"
    viewBox="0 0 46 50"
    {...props}
  >
    <path
      opacity=".2"
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6 42h-6l2.4 6h6l-2.4-6zM32 2v15a12.98 12.98 0 01-16.69 12.46A12.98 12.98 0 0036 19V2h-4z"
    />
    <path
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 6h-7V1a1 1 0 00-1-1H9a1 1 0 00-1 1v5H1a1 1 0 00-1 1v5c0 4.85 3.5 9 8.27 9.83a15.01 15.01 0 0010.4 11.53L17.2 40h-2.8a2 2 0 00-1.86 1.26l-2.4 6A2 2 0 0012 50h22a2 2 0 001.85-2.74l-2.4-6A2 2 0 0031.6 40h-2.8l-1.47-6.64a15.01 15.01 0 0010.4-11.53A9.98 9.98 0 0046 12V7a1 1 0 00-1-1zM8 19.75c-3.53-.92-6-4.1-6-7.75V8h6v11.75zM34 48H12l2.4-6h17.2l2.4 6zm-7.25-8h-7.5l1.38-6.2c.78.13 1.57.2 2.37.2s1.59-.07 2.37-.2l1.38 6.2zM23 32c-7.18 0-13-5.82-13-13V2h26v17c0 7.18-5.82 13-13 13zm21-20a8.02 8.02 0 01-6 7.75V8h6v4z"
    />
    <path
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 46h12a1 1 0 100-2H17a1 1 0 100 2z"
    />
  </SvgIcon>
)

export default Trophy
