const messages = {
  web_notfound_title: {
    en: '404',
    de: '404',
    nl: '404',
    fr: '404',
    it: '404',
    pt: '404',
    es: '404',
  },
  web_notfound_message: {
    en: 'Not found.',
    de: 'Nicht gefunden.',
    nl: 'Not found.',
    fr: 'Not found.',
    it: 'Not found.',
    pt: 'Not found.',
    es: 'Not found.',
  },
  web_notfound_action: {
    en: 'Go to games',
    de: 'Zurück zum Spiel.',
    nl: 'Go to games',
    fr: 'Go to games',
    it: 'Go to games',
    pt: 'Go to games',
    es: 'Go to games',
  },
  web_global_action_back: {
    en: 'Back',
    de: 'Zurück',
    nl: 'Terug',
    fr: 'Back',
    it: 'Back',
    pt: 'Back',
    es: 'Back',
  },
  web_groups_action_create: {
    en: `Create new group`,
    de: `Neue Gruppe erstellen`,
    nl: `Nieuwe groep aanmaken`,
    fr: `Create new group`,
    it: `Create new group`,
    pt: `Create new group`,
    es: `Create new group`,
  },
  web_friends_action_invite: {
    en: `Invite Friends`,
    de: `Freunde einladen`,
    nl: `Vrienden uitnodigen`,
    fr: `Invite Friends`,
    it: `Invite Friends`,
    pt: `Invite Friends`,
    es: `Invite Friends`,
  },
  web_predictioneditor_desktop_help_select: {
    en: `Use the numeric keypad on your keyboard to type your result.`,
    de: `Verwende deine Tastatur, um dein Ergebnis einzugeben.`,
    nl: `Use the numeric keypad on your keyboard to type your result.`,
    fr: `Use the numeric keypad on your keyboard to type your result.`,
    it: `Use the numeric keypad on your keyboard to type your result.`,
    pt: `Use the numeric keypad on your keyboard to type your result.`,
    es: `Use the numeric keypad on your keyboard to type your result.`,
  },
  web_predictioneditor_desktop_help_save: {
    en: `Press Return to save and go to the next game`,
    de: `Drücken Enter, um zu speichern und zum nächsten Spiel zu gelangen.`,
    nl: `Press Return to save and go to the next game`,
    fr: `Press Return to save and go to the next game`,
    it: `Press Return to save and go to the next game`,
    pt: `Press Return to save and go to the next game`,
    es: `Press Return to save and go to the next game`,
  },
  web_bild_games_empty_link: {
    de: 'Erfahre mehr über tackl',
  },
  web_bild_games_empty_action_start: {
    de: 'Freunde einladen & tackln',
  },
  web_bild_games_action_start: {
    de: 'Neues Freunde tackl starten',
  },
  web_home_tab_lotto: {
    en: 'Lotto',
    de: 'Lotto',
    nl: 'Lotto',
    fr: 'Lotto',
    it: 'Lotto',
    pt: 'Lotto',
    es: 'Lotto',
  },
  web_lotto_betslipmodal_action_back: {
    en: `Back to Lotto`,
    de: `Weiter zum Fussball Lotto`,
  },
  web_bild_lotto_tab_your_ticket: {
    de: 'Deine Tipps',
  },
  web_bild_lotto_tab_history: {
    de: 'Letzter Spieltag',
  },
  web_bild_groups_empty_title: {
    de: 'Tippgemeinschaften\\\nComing soon!',
  },
  web_bild_groups_empty_message: {
    de: 'Zur EM 2021 kannst du beim BILD Tippspiel auch komplette Wettbewerbe mit deinen Freunden tippen. Bis dahin schau dir unsere Partner-App tackl an.',
  },
  web_bild_groups_empty_cta: {
    de: 'tackl App ausprobieren',
  },
  web_bild_powered_by: {
    de: 'powered by',
  },
  web_bild_edit: {
    de: 'Ändern',
  },
  web_bild_lotto_empty_state_title: {
    de: 'BILD Fußball Lotto',
  },
  web_bild_lotto_empty_state_message: {
    de: 'Wer die 9 Top-Partien des Spieltags richtig tippt, gewinnt bis zu 1 Million Euro!',
  },
  web_bild_lotto_rules_link_label: {
    de: 'Spielregeln & Gewinne',
  },
  web_bild_lotto_empty_state_cta_active_label: {
    de: 'Hier um die Million tippen',
  },
  web_bild_lotto_empty_state_cta_inactive_label: {
    de: 'Tippen zur Zeit nicht möglich',
  },
  web_bild_lotto_active_games_tip_label: {
    de: 'Tippe Jederzeit mit Freunden auf Spiele deiner Wahl.',
  },
  web_bild_prediction_editor_slider_tip: {
    de: 'Um ein Ergebnis zu tippen bewege die Slider auf die Zahl deiner Wahl',
  },
  web_bild_prediction_editor_button_tip: {
    de: 'Wenn du fertig Bist klicke Hier oder swipe die Spielkarte nach unten',
  },
  web_bild_advertisement_label: {
    de: '-w-',
  },
  web_bild_lotto_match_card_bookie_button_label: {
    de: 'Deine Quote %1$s',
  },
  web_bild_presented_by: {
    de: 'presented by',
  },
  web_bild_lotto_game_details_tab_tipps_label: {
    de: 'Deine Tipps',
  },
  web_bild_lotto_game_details_tab_ranking_label: {
    de: 'Gewinnklassen',
  },
  web_bild_lotto_game_details_cta_new_game: {
    de: 'Zurück zum Fussball Lotto',
  },
  web_bild_lotto_game_details_tab_ranking_cta_match_details_label: {
    de: 'Spieldetails',
  },
  web_bild_close_handle: {
    de: `schließen`,
  },
  web_bild_lotto_leaderboard_header_name: {
    de: `Rang/Name`,
  },
  web_bild_lotto_leaderboard_header_prediction: {
    de: `Tipp`,
  },
  web_bild_lotto_leaderboard_header_score: {
    de: `R/F`,
  },
  web_bild_lotto_leaderboard_header_totalscore: {
    de: `Gesamt`,
  },
  web_bild_setup_skip_label: {
    de: `Überspringen`,
  },
  web_bild_welcome_title: {
    de: `Tippen und Millionär werden.`,
  },
  web_bild_welcome_message: {
    de: `Jetzt kostenlos teilnehmen, die 9 Top-Partien des Spieltags tippen und bis zu 1 Million Euro gewinnen!`,
  },
  web_bild_welcome_login_button_label: {
    de: `Mit BILD-Konto anmelden`,
  },
  web_bild_welcome_register_button_label: {
    de: `Kostenlos registrieren`,
  },
  web_bild_nofriends_action_playlotto: {
    de: `Lotto spielen`,
  },
  web_bild_social_share_title: {
    de: `Lade deine Freunde ein!`,
  },
  web_bild_social_share_type_email: {
    de: `Mail`,
  },
  web_bild_social_share_type_whatsapp: {
    de: `Whatsapp`,
  },
  web_bild_social_share_type_sms: {
    de: `SMS`,
  },
  web_bild_social_share_type_facebook: {
    de: `Facebook`,
  },
  web_bild_social_share_type_twitter: {
    de: `Twitter`,
  },
  web_bild_social_share_type_copylink: {
    de: `Link kopieren`,
  },
  web_bild_alert_logout_message: {
    de: `Möchten Sie sich wirklich vom Fussball Lotto abmelden?`,
  },
  web_bild_completion_private_message: {
    de: `Sobald einer Ihrer Freunde die Einladung akzeptiert hat, gibt es kein zurück mehr!`,
  },
  web_bild_lotto_gamedetails_help_prizedistribution: {
    de: `Gewinnklassen`,
  },
  web_bild_lotto_prediction_editor_title: {
    de: `BILD FUSSBALL LOTTO`,
  },
  web_bild_advertisement_extended_label: {
    de: `ANZEIGE`,
  },
  web_bild_lotto_conditions_link_label: {
    de: `Teilnahmebedingungen`,
  },
  web_bild_lotto_dataprotection_link_label: {
    de: `Datenschutzhinweis`,
  },
  web_bild_profile_action_faq: {
    de: `FAQ`,
  },
  web_bild_profile_action_conditions: {
    de: `Teilnahmebedingungen`,
  },
  web_bild_timeline_empty_cancelled_title: {
    de: `Spiel wird nicht gewertet.`,
  },
  web_gambling_legal_text: {
    de: `18+. Glücksspiel kann süchtig machen. Hilfe unter http://www.spielerambulanz.de Quotenänderungen vorbehalten.`,
  },
  web_ad: {
    de: `anzeige`,
  },
}

export default messages
