import { createContext } from 'react'
import { RpcError } from 'services/gamelogic'
import { AuthUserInfo } from 'services/auth'
import { UserProfile } from 'protos'

interface Context {
  authError?: Error | firebase.default.auth.Error
  sessionsLoginError?: RpcError | null
  userInfo: AuthUserInfo | null
  isAuthStateKnown: boolean
  referralParams?: UserProfile.IReferralParameters
}

const AuthContext = createContext<Context>({
  userInfo: null,
  isAuthStateKnown: false,
})

export default AuthContext
