import { withStyles, Tab, Theme, fade } from '@material-ui/core'

const BetSlipTab = withStyles((theme: Theme) => ({
  root: {
    minWidth: 0,
    fontSize: 12,
    minHeight: 36,
    textTransform: 'none',
    border: `1px solid transparent`,
    borderTopColor: fade(theme.palette.divider, 0.4),

    '&:first-of-type': {
      borderTopLeftRadius: 5,
    },

    '&:first-of-type:not($selected)': {
      borderLeftColor: fade(theme.palette.divider, 0.4),
    },

    '&:last-of-type': {
      borderTopRightRadius: 5,
    },

    '&:last-of-type:not($selected)': {
      borderRightColor: fade(theme.palette.divider, 0.4),
    },
  },
  selected: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderTopColor: theme.palette.divider,
    borderLeftColor: theme.palette.divider,
    borderRightColor: theme.palette.divider,
    backgroundColor: theme.palette.common.white,
  },
  textColorInherit: {
    opacity: 0.3,
  },
  wrapper: {
    whiteSpace: 'nowrap',
  },
}))(Tab)

export default BetSlipTab
