import React, { FC, ComponentProps } from 'react'
import cls from 'classnames'
import { makeStyles, Theme } from '@material-ui/core'
import { FadedActionDrawer } from 'components'
import { Sticky, ContentContainer } from 'tackl-material'

type Props = Pick<ComponentProps<typeof FadedActionDrawer>, 'action' | 'className'>

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    top: 'auto',
    bottom: 40,
    zIndex: theme.zIndex.drawer,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    ...theme.mixins.fadedBackground(theme),
  },
}))

const GameBottomAction: FC<Props> = ({ action, className }) => {
  const classes = useStyles()

  return (
    <Sticky className={cls(className, classes.root)}>
      <ContentContainer>
        {action as any /* WORKAROUND */}
      </ContentContainer>
    </Sticky>
  )
}

export default GameBottomAction
