import React, { FC } from 'react'

import { Box } from 'tackl-material'
import { PublicGroupGameIndicator } from 'components'

import { Props } from './propTypes'
import GameCardHeaderTime from './GameCardHeaderTime'
import GameCardHeaderTitle from './GameCardHeaderTitle'
import GameCardHeaderContainer from './GameCardHeaderContainer'

const PublicGroupGameCardHeader: FC<Props> = ({ game, isIndicatorHidden }) => {
  return (
    <GameCardHeaderContainer>
      {
        !isIndicatorHidden && (
          <PublicGroupGameIndicator state={game.state} />
        )
      }

      <Box
        pl={2}
        flex={1}
        minWidth={0}
        alignSelf="center"
      >
        <GameCardHeaderTime
          nextTime={game.nextTime}
          display={game.timeDisplay}
          startTime={game.startTime}
          timeOther={game.timeOther}
          finishTime={game.finishTime}
        />
        <GameCardHeaderTitle
          title={game.titleText}
        />
      </Box>
    </GameCardHeaderContainer>
  )
}

export default PublicGroupGameCardHeader
