import React, { FC, ReactNode } from 'react'
import { Markdown } from 'components'
import { useIntl } from 'hooks'

type Props = {
  unit?: ReactNode
  amount?: ReactNode
  custom?: string | null
}

const Currency: FC<Props> = ({ unit, amount, custom }) => {
  const { language } = useIntl()

  if (custom) {
    return <Markdown>{custom}</Markdown>
  }

  const displayedAmount = Number.isFinite(amount as number)
    ? Number(amount).toLocaleString(language)
    : amount

  return (
    <>
      {displayedAmount}{unit}
    </>
  )
}

Currency.defaultProps = {
  amount: 0,
  unit: '',
}

export default Currency
