type MobileOS = 'android' | 'ios' | 'windows_phone'

// based on: https://stackoverflow.com/a/21742107
const getMobileOperatingSystem = (): MobileOS | undefined => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera

  // Windows Phone must come first because its UA also contains 'Android'
  if (/windows phone/i.test(userAgent)) {
      return 'windows_phone'
  }

  if (/android/i.test(userAgent)) {
      return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios'
  }

  return undefined
}

export default getMobileOperatingSystem
