import React, { FC, ComponentProps, ComponentType } from 'react'
import cls from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { JerseyStriped } from 'components'
import { Theme, LinearProgress } from '@material-ui/core'
import { Box, CenteredBox, Typography } from 'tackl-material'
import { ActionProps } from './propTypes'
import MatchCardEditActions from './MatchCardEditActions'

type Props = {
  homeTeamName?: string | null
  awayTeamName?: string | null
  homeTeamColor?: string | null
  awayTeamColor?: string | null
  matchProgress?: number | null
  actionComponent?: ComponentType<ActionProps>
} & ActionProps & ComponentProps<typeof CenteredBox>

const useStyles = makeStyles<Theme>(({ palette, shape }) => ({
  root: {
    height: 60,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: palette.neutral[100].main,
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius,
    '&::after': {
      top: 0,
      left: 'calc(50% - 0.5px)',
      bottom: 0,
      content: '""',
      position: 'absolute',
      borderLeft: `1px dashed ${palette.divider}`,
    },
    '& *': {
      zIndex: 1,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  team: {
    lineHeight: '14px',
    display: '-webkit-box',
    overflow: 'hidden',
    lineClamp: 3,
    wordBreak: 'break-word',
    boxOrient: 'vertical',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  teamContainer: {
    flex: 1,
    display: 'flex',
    marginTop: -1,
    '&$left': {
      justifyContent: 'flex-end',
    },
    '&$right': {
      justifyContent: 'flex-start',
    },
  },
  jerseyContainer: {
    width: 72,
    display: 'flex',
    alignSelf: 'start',
    '&$left': {
      marginLeft: -32,
    },
    '&$right': {
      marginRight: -32,
    },
  },
  progress: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 3,
    position: 'absolute',
  },
  left: {},
  right: {},
}))

const MatchCardHeader: FC<Props> = ({
  isLive,
  className,
  homeScore = 0,
  awayScore = 0,
  isEditable,
  isSelected,
  homeTeamName = '-',
  awayTeamName = '-',
  homeTeamColor,
  awayTeamColor,
  matchProgress = 0,
  isPredictionMissing,
  actionComponent: ActionComponent = MatchCardEditActions,
  ...props
}) => {
  const classes = useStyles()

  return (
    <CenteredBox
      className={cls(classes.root, className)}
      {...props}
    >
      <Box className={cls(classes.jerseyContainer, classes.left)}>
        <JerseyStriped
          color={homeTeamColor}
          stripeOrigin="left"
        />
      </Box>

      <Box className={cls(classes.teamContainer, classes.left)}>
        <Typography
          align="right"
          variant="body2"
          className={cls(classes.bold, classes.team)}
        >
          {homeTeamName}
        </Typography>
      </Box>

      <ActionComponent
        homeScore={homeScore}
        awayScore={awayScore}
        isLive={isLive}
        isSelected={isSelected}
        isEditable={isEditable}
        isPredictionMissing={isPredictionMissing}
      />

      <Box className={cls(classes.teamContainer, classes.right)}>
        <Typography
          variant="body2"
          className={cls(classes.bold, classes.team)}
        >
          {awayTeamName}
        </Typography>
      </Box>

      <Box className={cls(classes.jerseyContainer, classes.right)}>
        <JerseyStriped
          color={awayTeamColor}
          stripeOrigin="right"
        />
      </Box>

      {isLive && (
        <LinearProgress
          value={Math.round(Number(matchProgress) * 100)}
          variant="determinate"
          className={classes.progress}
        />
      )}
    </CenteredBox>
  )
}

export default MatchCardHeader
