import React from 'react'
import cls from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Box from '@material-ui/core/Box'
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

interface TextFieldProps extends InputBaseProps {
  label?: React.ReactNode,
  spacing?: 'fixed' | 'auto'
  helperText?: React.ReactNode
}

const useStyles = makeStyles(({ shape, palette, spacing }: Theme) => ({
  root: {
    border: '1px solid',
    borderColor: palette.divider,
    borderRadius: shape.borderRadius,
    backgroundColor: palette.common.white,
  },
  label: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    borderRightWidth: 1,
    borderColor: 'inherit',
    borderRightStyle: 'solid',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    borderTopLeftRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
  },
  fixedSizedLabel: {
    minWidth: 92,
    maxWidth: 92,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  autoSizedLabel: {
    width: 'auto',
  },
  textContainer: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  helperText: {
    paddingLeft: spacing(2),
  },
}))

const TextField: React.FC<TextFieldProps> = ({
  id,
  label,
  disabled,
  helperText,
  spacing = 'fixed',
  ...props
}) => {
  const classes = useStyles()

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        className={classes.root}
      >
        <InputLabel
          disabled={disabled}
          htmlFor={id}
          className={cls(classes.label, {
            [classes.fixedSizedLabel]: spacing === 'fixed',
            [classes.autoSizedLabel]: spacing === 'auto',
          })}
        >
          {label}
        </InputLabel>
        <InputBase
          id={id}
          {...props}
          fullWidth
          disabled={disabled}
          className={classes.textContainer}
        />
      </Box>
      {helperText && (
        <FormHelperText className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  )
}

export default TextField
