import React, { FC, ComponentProps } from 'react'
import cls from 'classnames'
import { makeStyles } from '@material-ui/core'
import { Fab } from 'tackl-material'
import { Down } from 'tackl-material/Icons'
import { FormattedMessage } from 'components'

const useStyles = makeStyles(() => ({
  disableHoverBg: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const SkipButton: FC<Partial<ComponentProps<typeof Fab>>> = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <Fab
      size="large"
      color="secondary"
      {...props}
      className={cls(className, classes.disableHoverBg)}
    >
      <FormattedMessage id="predictioneditor_action_skip" />
      <Down />
    </Fab>
  )
}

export default SkipButton
