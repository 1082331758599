/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.setOptions({
  java_package: "com.tackl.proto.request",
  java_multiple_files: true,
  go_package: "rpctypes",
  objc_class_prefix: "Proto"
})
.addJSON({
  Attribution: {
    fields: {
      deviceId: {
        type: "string",
        id: 1
      },
      trackerToken: {
        type: "string",
        id: 2
      },
      trackerName: {
        type: "string",
        id: 3
      },
      network: {
        type: "string",
        id: 4
      },
      campaign: {
        type: "string",
        id: 5
      },
      adgroup: {
        type: "string",
        id: 6
      },
      creative: {
        type: "string",
        id: 7
      },
      clickLabel: {
        type: "string",
        id: 8
      },
      adid: {
        type: "string",
        id: 9
      }
    }
  },
  BetOffer: {
    fields: {
      sponsor: {
        type: "Sponsor",
        id: 6
      },
      market_1x2: {
        type: "Market1X2",
        id: 7
      },
      predictionEnabled: {
        type: "bool",
        id: 8
      },
      gameOddsEnabled: {
        type: "bool",
        id: 9
      }
    },
    reserved: [
      [
        1,
        1
      ],
      [
        2,
        2
      ],
      [
        3,
        3
      ],
      [
        4,
        4
      ],
      [
        5,
        5
      ]
    ],
    nested: {
      Sponsor: {
        fields: {
          sponsorId: {
            type: "string",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          },
          logoUrl: {
            type: "string",
            id: 3
          },
          logoUrlLight: {
            type: "string",
            id: 4
          }
        }
      },
      Market1X2: {
        fields: {
          home: {
            type: "Market",
            id: 3
          },
          draw: {
            type: "Market",
            id: 4
          },
          away: {
            type: "Market",
            id: 5
          }
        }
      },
      Market: {
        fields: {
          odd: {
            type: "string",
            id: 1
          },
          url: {
            type: "string",
            id: 2
          },
          probabilityPercent: {
            type: "int32",
            id: 3
          }
        }
      }
    }
  },
  BetSlip: {
    fields: {
      screenTitle: {
        type: "string",
        id: 1
      },
      screenMessage: {
        type: "string",
        id: 2
      },
      slipTitle: {
        type: "string",
        id: 3
      },
      slipTimestamp: {
        type: "int64",
        id: 4
      },
      slipSubtitle: {
        type: "string",
        id: 5
      },
      sponsorLogoUrl: {
        type: "string",
        id: 6
      },
      ingameCta: {
        type: "string",
        id: 7
      },
      linkCta: {
        type: "string",
        id: 8
      },
      linkUrl: {
        type: "string",
        id: 9
      },
      bet: {
        rule: "repeated",
        type: "Bet",
        id: 10
      },
      footerStake: {
        type: "string",
        id: 11
      },
      footerFee: {
        type: "string",
        id: 12
      },
      footerPrizeTitle: {
        type: "string",
        id: 13
      },
      footerPrizeValue: {
        type: "string",
        id: 14
      },
      tabTitle: {
        type: "string",
        id: 15
      },
      bonusMessage: {
        type: "string",
        id: 16
      },
      bonusCode: {
        type: "string",
        id: 17
      }
    },
    nested: {
      Bet: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          subtitle: {
            type: "string",
            id: 2
          },
          odds: {
            type: "string",
            id: 3
          }
        }
      }
    }
  },
  Bonus: {
    fields: {
      bonusId: {
        type: "string",
        id: 1
      },
      value: {
        type: "Currency",
        id: 2
      },
      available: {
        type: "bool",
        id: 3
      },
      cooldownSec: {
        type: "int32",
        id: 4
      },
      maxCooldownSec: {
        type: "int32",
        id: 5
      }
    }
  },
  Competition: {
    fields: {
      competitionId: {
        type: "string",
        id: 1
      },
      competitionName: {
        type: "string",
        id: 2
      },
      logoUrl: {
        type: "string",
        id: 3
      },
      primaryColor: {
        type: "string",
        id: 4
      },
      secondaryColor: {
        type: "string",
        id: 7
      },
      countryCode: {
        type: "string",
        id: 5
      },
      abbreviation: {
        type: "string",
        id: 6
      },
      seasonName: {
        type: "string",
        id: 8
      }
    }
  },
  Currency: {
    fields: {
      amount: {
        type: "int64",
        id: 1
      },
      unit: {
        type: "string",
        id: 2
      },
      drinkId: {
        type: "string",
        id: 4
      },
      drinkName: {
        type: "string",
        id: 5
      },
      custom: {
        type: "string",
        id: 8
      }
    },
    reserved: [
      [
        3,
        3
      ],
      [
        6,
        6
      ],
      [
        7,
        7
      ]
    ]
  },
  Game: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      gameType: {
        type: "GameType",
        id: 2
      },
      state: {
        type: "State",
        id: 3
      },
      competition: {
        type: "Competition",
        id: 4
      },
      roundName: {
        type: "string",
        id: 5
      },
      matchesTotal: {
        type: "int32",
        id: 6
      },
      matchesFinished: {
        type: "int32",
        id: 7
      },
      matchesMissing: {
        type: "int32",
        id: 8
      },
      startTime: {
        type: "int64",
        id: 10
      },
      nextTime: {
        type: "int64",
        id: 11
      },
      finishTime: {
        type: "int64",
        id: 12
      },
      entryFee: {
        type: "Currency",
        id: 20
      },
      firstPrize: {
        type: "Currency",
        id: 21
      },
      currentPrize: {
        type: "Currency",
        id: 22
      },
      currentRank: {
        type: "int32",
        id: 30
      },
      playerCount: {
        type: "int32",
        id: 31
      },
      titleText: {
        type: "string",
        id: 51
      },
      timeDisplay: {
        type: "TimeDisplay",
        id: 52
      },
      timeOther: {
        type: "string",
        id: 53
      },
      showPredictNext: {
        type: "bool",
        id: 56
      },
      infobarText: {
        type: "string",
        id: 58
      },
      infobarUser: {
        type: "User",
        id: 59
      },
      leaderboardPreview: {
        rule: "repeated",
        type: "GameMember",
        id: 60
      },
      showMemberState: {
        type: "bool",
        id: 61
      },
      prizeValue: {
        type: "Currency",
        id: 62
      },
      prizeTitle: {
        type: "string",
        id: 63
      },
      betslip: {
        type: "BetSlip",
        id: 64
      },
      cardCta: {
        type: "string",
        id: 65
      },
      multiBetslip: {
        rule: "repeated",
        type: "BetSlip",
        id: 66
      }
    },
    reserved: [
      [
        32,
        32
      ],
      [
        33,
        33
      ],
      [
        34,
        34
      ],
      [
        50,
        50
      ],
      [
        54,
        54
      ],
      [
        55,
        55
      ],
      [
        57,
        57
      ]
    ],
    nested: {
      GameType: {
        values: {
          GT_UNSET: 0,
          GT_PRIVATE_HOSTED: 1,
          GT_PRIVATE_INVITED: 2,
          GT_PUBLIC: 3,
          GT_JACKPOT: 4,
          GT_LOTTERY: 5,
          GT_PUBLIC_GROUP: 6
        }
      },
      State: {
        values: {
          GS_UNSET: 0,
          GS_PENDING: 1,
          GS_UPCOMING: 2,
          GS_RUNNING_LIVE: 3,
          GS_RUNNING_WAITING: 4,
          GS_FINISHED_WIN: 5,
          GS_FINISHED_LOSS: 6,
          GS_FINISHED_OTHER: 7,
          GS_FINISHED_DRAW: 8
        }
      },
      TimeDisplay: {
        values: {
          TD_NONE: 0,
          TD_OTHER: 1,
          TD_SHOW_START: 2,
          TD_LIVE: 3,
          TD_SHOW_NEXT: 4,
          TD_SHOW_FINISH: 5
        }
      }
    }
  },
  GameDetails: {
    fields: {
      game: {
        type: "Game",
        id: 1
      },
      match: {
        rule: "repeated",
        type: "Match",
        id: 2
      },
      member: {
        rule: "repeated",
        type: "GameMember",
        id: 3
      },
      ownEntry: {
        type: "GameMember",
        id: 4
      },
      totalPot: {
        type: "Currency",
        id: 5
      },
      prizeDistribution: {
        rule: "repeated",
        type: "PrizeLevel",
        id: 6
      },
      showMemberState: {
        type: "bool",
        id: 9
      },
      inviteEnabled: {
        type: "bool",
        id: 10
      },
      groupDetails: {
        type: "GroupDetails",
        id: 11
      },
      chatPath: {
        type: "string",
        id: 12
      },
      muted: {
        type: "bool",
        id: 13
      },
      drinkPrize: {
        type: "DrinkPrizeDetails",
        id: 14
      },
      prizeCta: {
        type: "string",
        id: 15
      },
      prizeNotification: {
        type: "Notification",
        id: 17
      },
      centerTitle: {
        type: "string",
        id: 18
      },
      centerDescription: {
        type: "string",
        id: 19
      },
      centerImageUrl: {
        type: "string",
        id: 20
      },
      leaderboardGroup: {
        rule: "repeated",
        type: "LeaderboardGroup",
        id: 21
      },
      prizeDistributionFooter: {
        type: "string",
        id: 22
      }
    },
    reserved: [
      [
        7,
        7
      ],
      [
        8,
        8
      ],
      [
        16,
        16
      ]
    ],
    nested: {
      PrizeLevel: {
        fields: {
          prize: {
            type: "Currency",
            id: 3
          },
          title: {
            type: "string",
            id: 4
          }
        },
        reserved: [
          [
            1,
            1
          ],
          [
            2,
            2
          ]
        ]
      },
      DrinkPrizeDetails: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          wager: {
            type: "Currency",
            id: 2
          },
          helpText: {
            type: "string",
            id: 3
          },
          topPrizeTitle: {
            type: "string",
            id: 4
          },
          topPrize: {
            type: "Currency",
            id: 5
          },
          ownPrizeTitle: {
            type: "string",
            id: 6
          },
          ownPrize: {
            type: "Currency",
            id: 7
          }
        }
      },
      LeaderboardGroup: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          entry: {
            rule: "repeated",
            type: "GameMember",
            id: 2
          }
        }
      }
    }
  },
  GameMember: {
    fields: {
      user: {
        type: "User",
        id: 1
      },
      state: {
        type: "string",
        id: 2
      },
      rank: {
        type: "uint32",
        id: 3
      },
      totalScore: {
        type: "int32",
        id: 4
      },
      prediction: {
        rule: "repeated",
        type: "Prediction",
        id: 5
      },
      currentPrize: {
        type: "Currency",
        id: 6
      },
      pending: {
        type: "bool",
        id: 7
      },
      rankDelta: {
        type: "int32",
        id: 8
      },
      roundWins: {
        type: "int32",
        id: 9
      }
    }
  },
  GameTemplate: {
    fields: {
      templateId: {
        type: "string",
        id: 1
      },
      templateType: {
        type: "TemplateType",
        id: 2
      },
      startTime: {
        type: "int64",
        id: 5
      },
      competition: {
        type: "Competition",
        id: 6
      },
      roundName: {
        type: "string",
        id: 7
      },
      entryFee: {
        type: "Currency",
        id: 8
      },
      playerCount: {
        type: "int32",
        id: 9
      },
      goalTitle: {
        type: "string",
        id: 10
      },
      goalDescription: {
        type: "string",
        id: 11
      },
      goalImageUrl: {
        type: "string",
        id: 12
      },
      infobarText: {
        type: "string",
        id: 14
      },
      titleText: {
        type: "string",
        id: 15
      },
      firstPrize: {
        type: "Currency",
        id: 16
      },
      totalPot: {
        type: "Currency",
        id: 17
      },
      cardCta: {
        type: "string",
        id: 18
      }
    },
    reserved: [
      [
        13,
        13
      ]
    ],
    nested: {
      TemplateType: {
        values: {
          GT_UNSET: 0,
          GT_PUBLIC: 1,
          GT_JACKPOT: 2,
          GT_LOTTERY: 3,
          GT_PUBLIC_GROUP: 4
        }
      }
    }
  },
  GroupDetails: {
    fields: {
      currentRound: {
        type: "Round",
        id: 1
      },
      round: {
        rule: "repeated",
        type: "Round",
        id: 2
      },
      totalLeaderboard: {
        rule: "repeated",
        type: "GameMember",
        id: 3
      },
      host: {
        type: "GameMember",
        id: 5
      },
      welcomeMessage: {
        type: "string",
        id: 6
      }
    },
    reserved: [
      [
        4,
        4
      ]
    ],
    nested: {
      Round: {
        fields: {
          roundId: {
            type: "string",
            id: 1
          },
          title: {
            type: "string",
            id: 2
          },
          active: {
            type: "bool",
            id: 3
          },
          state: {
            type: "State",
            id: 4
          }
        },
        nested: {
          State: {
            values: {
              GRS_UNKNOWN: 0,
              GRS_UPCOMING: 1,
              GRS_LIVE: 2,
              GRS_FINISHED: 3
            }
          }
        }
      }
    }
  },
  Link: {
    fields: {
      title: {
        type: "string",
        id: 1
      },
      url: {
        type: "string",
        id: 2
      },
      iconUrl: {
        type: "string",
        id: 3
      }
    }
  },
  Match: {
    fields: {
      matchId: {
        type: "string",
        id: 1
      },
      startTime: {
        type: "int64",
        id: 2
      },
      state: {
        type: "State",
        id: 3
      },
      homeTeam: {
        type: "Team",
        id: 4
      },
      awayTeam: {
        type: "Team",
        id: 5
      },
      clock: {
        type: "string",
        id: 6
      },
      progress: {
        type: "double",
        id: 7
      },
      currentScore: {
        type: "Score",
        id: 8
      },
      cancelReason: {
        type: "string",
        id: 10
      },
      available: {
        type: "bool",
        id: 11
      },
      featured: {
        type: "bool",
        id: 14
      },
      competition: {
        type: "Competition",
        id: 15
      },
      roundId: {
        type: "string",
        id: 16
      },
      betOffer: {
        type: "BetOffer",
        id: 17
      }
    },
    reserved: [
      [
        9,
        9
      ],
      [
        12,
        12
      ],
      [
        13,
        13
      ]
    ],
    nested: {
      State: {
        values: {
          MS_UNSET: 0,
          MS_UPCOMING: 1,
          MS_LIVE: 2,
          MS_FINISHED: 3,
          MS_CANCELLED: 4
        }
      }
    }
  },
  MatchTimeline: {
    fields: {
      match: {
        type: "Match",
        id: 1
      },
      halftimeScore: {
        type: "Score",
        id: 2
      },
      fulltimeScore: {
        type: "Score",
        id: 3
      },
      extratimeScore: {
        type: "Score",
        id: 4
      },
      scoreChange: {
        rule: "repeated",
        type: "ScoreChangeEvent",
        id: 5
      },
      card: {
        rule: "repeated",
        type: "CardEvent",
        id: 6
      },
      substitution: {
        rule: "repeated",
        type: "SubstitutionEvent",
        id: 7
      }
    },
    nested: {
      ScoreChangeEvent: {
        fields: {
          eventId: {
            type: "string",
            id: 1
          },
          clock: {
            type: "string",
            id: 2
          },
          homeSide: {
            type: "bool",
            id: 3
          },
          player: {
            type: "string",
            id: 4
          },
          newScore: {
            type: "Score",
            id: 5
          },
          typeSuffix: {
            type: "string",
            id: 6
          }
        }
      },
      CardEvent: {
        fields: {
          eventId: {
            type: "string",
            id: 1
          },
          clock: {
            type: "string",
            id: 2
          },
          homeSide: {
            type: "bool",
            id: 3
          },
          player: {
            type: "string",
            id: 4
          },
          cardType: {
            type: "string",
            id: 5
          }
        }
      },
      SubstitutionEvent: {
        fields: {
          eventId: {
            type: "string",
            id: 1
          },
          clock: {
            type: "string",
            id: 2
          },
          homeSide: {
            type: "bool",
            id: 3
          },
          playerOut: {
            type: "string",
            id: 4
          },
          playerIn: {
            type: "string",
            id: 5
          }
        }
      }
    }
  },
  Notification: {
    fields: {
      notificationId: {
        type: "string",
        id: 1
      },
      title: {
        type: "string",
        id: 2
      },
      message: {
        type: "string",
        id: 3
      },
      action: {
        type: "string",
        id: 4
      },
      creditValue: {
        type: "Currency",
        id: 5
      },
      creditTheme: {
        type: "CreditTheme",
        id: 6
      },
      drinkValue: {
        type: "Currency",
        id: 7
      },
      imageUrl: {
        type: "string",
        id: 8
      },
      extraAction: {
        type: "ExtraAction",
        id: 9
      },
      buttonLayout: {
        type: "ButtonLayout",
        id: 10
      },
      footer: {
        type: "string",
        id: 11
      }
    },
    nested: {
      CreditTheme: {
        values: {
          CT_NONE: 0,
          CT_POSITIVE: 1,
          CT_NEGATIVE: 2,
          CT_OTHER: 3
        }
      },
      ButtonLayout: {
        values: {
          BL_HORIZONTAL: 0,
          BL_VERTICAL: 1
        }
      },
      ExtraAction: {
        fields: {
          actionTitle: {
            type: "string",
            id: 1
          },
          actionType: {
            type: "ActionType",
            id: 2
          },
          bonusId: {
            type: "string",
            id: 4
          },
          url: {
            type: "string",
            id: 5
          },
          promoCode: {
            type: "string",
            id: 6
          },
          claimEmail: {
            type: "string",
            id: 7
          },
          altNotificationId: {
            type: "string",
            id: 8
          }
        },
        nested: {
          ActionType: {
            values: {
              AT_DISMISS: 0,
              AT_CLAIM_BONUS: 1,
              AT_OPEN_URL: 2,
              AT_CLAIM_BONUS_WITH_EMAIL: 3,
              AT_CONFIRM_ALT_NOTIFICATION: 4
            }
          }
        }
      }
    }
  },
  NotificationSettings: {
    fields: {
      group: {
        rule: "repeated",
        type: "Group",
        id: 1
      }
    },
    nested: {
      Group: {
        fields: {
          groupTitle: {
            type: "string",
            id: 1
          },
          topic: {
            rule: "repeated",
            type: "Topic",
            id: 2
          }
        }
      },
      Topic: {
        fields: {
          topicId: {
            type: "string",
            id: 1
          },
          title: {
            type: "string",
            id: 2
          },
          enabled: {
            type: "bool",
            id: 3
          }
        }
      }
    }
  },
  Opponent: {
    fields: {
      user: {
        type: "User",
        id: 1
      },
      gamesCount: {
        type: "int32",
        id: 2
      },
      winCount: {
        type: "int32",
        id: 3
      },
      winRatio: {
        type: "double",
        id: 4
      },
      drinkBalance: {
        type: "Currency",
        id: 5
      },
      creditBalance: {
        type: "Currency",
        id: 6
      },
      rank: {
        type: "uint32",
        id: 8
      },
      selected: {
        type: "bool",
        id: 9
      },
      enabled: {
        type: "bool",
        id: 10
      },
      subtitle: {
        type: "string",
        id: 11
      }
    },
    reserved: [
      [
        7,
        7
      ]
    ]
  },
  OpponentHighlight: {
    values: {
      OPH_NONE: 0,
      OPH_GAMES_COUNT: 1,
      OPH_WIN_COUNT: 2,
      OPH_WIN_RATIO: 3,
      OPH_DRINK_BALANCE: 4,
      OPH_CREDIT_BALANCE: 5
    }
  },
  Prediction: {
    fields: {
      matchId: {
        type: "string",
        id: 1
      },
      predictedScore: {
        type: "Score",
        id: 2
      },
      value: {
        type: "int32",
        id: 3
      },
      editable: {
        type: "bool",
        id: 4
      }
    }
  },
  BoolPreference: {
    values: {
      BP_UNCHANGED: 0,
      BP_ENABLE: 1,
      BP_DISABLE: 2
    }
  },
  Score: {
    fields: {
      homeScore: {
        type: "int32",
        id: 1
      },
      awayScore: {
        type: "int32",
        id: 2
      }
    }
  },
  Sort: {
    fields: {
      sortId: {
        type: "string",
        id: 1
      },
      title: {
        type: "string",
        id: 2
      },
      active: {
        type: "bool",
        id: 3
      }
    }
  },
  StoreCreditProduct: {
    fields: {
      productId: {
        type: "string",
        id: 1
      },
      productValue: {
        type: "Currency",
        id: 2
      },
      productTitle: {
        type: "string",
        id: 3
      }
    }
  },
  Team: {
    fields: {
      teamId: {
        type: "string",
        id: 1
      },
      teamName: {
        type: "string",
        id: 2
      },
      tlaName: {
        type: "string",
        id: 3
      },
      logoUrl: {
        type: "string",
        id: 4
      },
      primaryColor: {
        type: "string",
        id: 5
      },
      leagueStanding: {
        type: "int32",
        id: 6
      }
    }
  },
  User: {
    fields: {
      userId: {
        type: "string",
        id: 1
      },
      nickname: {
        type: "string",
        id: 2
      },
      photoUrl: {
        type: "string",
        id: 3
      },
      firstName: {
        type: "string",
        id: 4
      },
      lastName: {
        type: "string",
        id: 5
      },
      relationship: {
        type: "Relationship",
        id: 6
      }
    },
    nested: {
      Relationship: {
        values: {
          REL_UNSET: 0,
          REL_SELF: 1,
          REL_REQUEST_RECEIVED: 2,
          REL_REQUEST_SENT: 3,
          REL_FRIEND: 4
        }
      }
    }
  },
  UserProfile: {
    fields: {
      user: {
        type: "User",
        id: 1
      },
      favoriteTeam: {
        type: "Team",
        id: 2
      },
      gameCount: {
        type: "int32",
        id: 4
      },
      winRatio: {
        type: "double",
        id: 5
      },
      drinkBalanceTotal: {
        type: "Currency",
        id: 7
      },
      victim: {
        type: "Opponent",
        id: 8
      },
      rival: {
        type: "Opponent",
        id: 9
      },
      creditBalance: {
        type: "Currency",
        id: 10
      },
      dailyBonus: {
        type: "Bonus",
        id: 11
      },
      favoriteTeamCompetitionName: {
        type: "string",
        id: 12
      },
      referral: {
        type: "ReferralParameters",
        id: 13
      },
      topFriend: {
        rule: "repeated",
        type: "Opponent",
        id: 14
      },
      topFriendsTitle: {
        type: "string",
        id: 15
      },
      friendCount: {
        type: "int32",
        id: 16
      },
      mutualFriendCount: {
        type: "int32",
        id: 17
      },
      promotion: {
        rule: "repeated",
        type: "Promotion",
        id: 18
      },
      advertisementsEnabled: {
        type: "bool",
        id: 19
      },
      streak: {
        rule: "repeated",
        type: "Streak",
        id: 20
      },
      streakTitle: {
        type: "string",
        id: 21
      },
      streakSubtitle: {
        type: "string",
        id: 22
      }
    },
    reserved: [
      [
        3,
        3
      ],
      [
        6,
        6
      ]
    ],
    nested: {
      ReferralParameters: {
        fields: {
          referralUrl: {
            type: "string",
            id: 1
          },
          referralBonus: {
            type: "Currency",
            id: 2
          },
          sharedSubject: {
            type: "string",
            id: 3
          },
          sharedMessage: {
            type: "string",
            id: 4
          }
        }
      },
      Promotion: {
        fields: {
          promotionCta: {
            type: "string",
            id: 1
          },
          promotionNotification: {
            type: "Notification",
            id: 2
          }
        }
      },
      Streak: {
        fields: {
          streakTitle: {
            type: "string",
            id: 1
          },
          step: {
            rule: "repeated",
            type: "Step",
            id: 2
          },
          streakNotification: {
            type: "Notification",
            id: 3
          },
          streakCta: {
            type: "string",
            id: 4
          }
        },
        nested: {
          Step: {
            fields: {
              stepValue: {
                type: "int32",
                id: 1
              },
              stepTotal: {
                type: "int32",
                id: 2
              },
              stepTitle: {
                type: "string",
                id: 3
              },
              stepPrize: {
                type: "string",
                id: 4
              },
              prizeClaimed: {
                type: "bool",
                id: 5
              },
              stepNotification: {
                type: "Notification",
                id: 6
              }
            }
          }
        }
      }
    }
  },
  GamesAcceptInvitationRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      force: {
        type: "bool",
        id: 2
      }
    }
  },
  GamesAcceptInvitationResponse: {
    fields: {
      gameDetails: {
        type: "GameDetails",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_ALREADY_DECLINED: 2,
          ERROR_EXPIRED: 3,
          ERROR_NOT_FOUND: 4,
          ERROR_INSUFFICIENT_BALANCE: 5,
          WARNING_STARTED_MATCHES: 6
        }
      }
    }
  },
  GamesCancelPrivateRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      }
    }
  },
  GamesCancelPrivateResponse: {
    fields: {
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_ALREADY_ACCEPTED: 2,
          ERROR_NOT_FOUND: 3
        }
      }
    }
  },
  GamesCreatePrivateRequest: {
    fields: {
      matchId: {
        rule: "repeated",
        type: "string",
        id: 1
      },
      prediction: {
        rule: "repeated",
        type: "Prediction",
        id: 2
      },
      inviteeId: {
        rule: "repeated",
        type: "string",
        id: 4
      },
      nonce: {
        type: "string",
        id: 5
      },
      force: {
        type: "bool",
        id: 6
      },
      drinkId: {
        type: "string",
        id: 7
      },
      entryFee: {
        type: "int32",
        id: 8
      },
      roundId: {
        type: "string",
        id: 9
      },
      customDrink: {
        type: "CustomDrink",
        id: 10
      }
    },
    reserved: [
      [
        3,
        3
      ]
    ],
    nested: {
      CustomDrink: {
        fields: {
          symbol: {
            type: "string",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          }
        }
      }
    }
  },
  GamesCreatePrivateResponse: {
    fields: {
      newGame: {
        type: "Game",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NO_MATCHES: 2,
          ERROR_INSUFFICIENT_BALANCE: 3,
          ERROR_INVALID_INVITEES: 4,
          WARNING_STARTED_MATCHES: 5,
          WARNING_RETRY_LATER: 6
        }
      }
    }
  },
  GamesCreatePublicRequest: {
    fields: {
      templateId: {
        type: "string",
        id: 1
      },
      prediction: {
        rule: "repeated",
        type: "Prediction",
        id: 2
      },
      nonce: {
        type: "string",
        id: 3
      },
      force: {
        type: "bool",
        id: 4
      }
    }
  },
  GamesCreatePublicResponse: {
    fields: {
      newGame: {
        type: "Game",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NO_MATCHES: 2,
          ERROR_INSUFFICIENT_BALANCE: 3,
          WARNING_STARTED_MATCHES: 4,
          WARNING_RETRY_LATER: 5,
          ERROR_USER_LIMIT_REACHED: 6
        }
      }
    }
  },
  GamesDeclineInvitationRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      }
    }
  },
  GamesDeclineInvitationResponse: {
    fields: {
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_ALREADY_ACCEPTED: 2,
          ERROR_NOT_FOUND: 3
        }
      }
    }
  },
  GamesEditPredictionsRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      prediction: {
        rule: "repeated",
        type: "Prediction",
        id: 2
      }
    }
  },
  GamesEditPredictionsResponse: {
    fields: {
      gameDetails: {
        type: "GameDetails",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_GAME_FINISHED: 2
        }
      }
    }
  },
  GamesGetActiveRequest: {
    fields: {
      stream: {
        type: "bool",
        id: 1
      },
      lotteryMode: {
        type: "LotteryMode",
        id: 4
      }
    },
    nested: {
      LotteryMode: {
        values: {
          LM_LOTTERY_DISABLED: 0,
          LM_LOTTERY_EXCLUSIVE: 1,
          LM_LOTTERY_INCLUSIVE: 2
        }
      }
    }
  },
  GamesGetActiveResponse: {
    fields: {
      group: {
        rule: "repeated",
        type: "Group",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      notification: {
        rule: "repeated",
        type: "Notification",
        id: 3
      },
      sponsoredMatch: {
        type: "Match",
        id: 6
      },
      availableLottery: {
        type: "GameTemplate",
        id: 7
      },
      bookieLinkCta: {
        type: "string",
        id: 12
      },
      bookieLinkUrl: {
        type: "string",
        id: 13
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    reserved: [
      [
        4,
        4
      ],
      [
        8,
        8
      ],
      [
        9,
        9
      ],
      [
        10,
        10
      ],
      [
        11,
        11
      ]
    ],
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      },
      Group: {
        fields: {
          game: {
            rule: "repeated",
            type: "Game",
            id: 2
          },
          formattedTitle: {
            type: "string",
            id: 3
          }
        },
        reserved: [
          [
            1,
            1
          ]
        ]
      }
    }
  },
  GamesGetDetailsRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      }
    }
  },
  GamesGetDetailsResponse: {
    fields: {
      gameDetails: {
        type: "GameDetails",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NOT_FOUND: 2
        }
      }
    }
  },
  GamesGetHistoryRequest: {
    fields: {
      stream: {
        type: "bool",
        id: 1
      },
      sortId: {
        type: "string",
        id: 2
      },
      markSeen: {
        type: "bool",
        id: 3
      },
      lotteryMode: {
        type: "LotteryMode",
        id: 4
      }
    },
    nested: {
      LotteryMode: {
        values: {
          LM_LOTTERY_DISABLED: 0,
          LM_LOTTERY_EXCLUSIVE: 1,
          LM_LOTTERY_INCLUSIVE: 2
        }
      }
    }
  },
  GamesGetHistoryResponse: {
    fields: {
      group: {
        rule: "repeated",
        type: "Group",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      sort: {
        rule: "repeated",
        type: "Sort",
        id: 3
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      },
      Group: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          game: {
            rule: "repeated",
            type: "Game",
            id: 2
          }
        }
      }
    }
  },
  GamesGetInvitableFriendsRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      }
    }
  },
  GamesGetInvitableFriendsResponse: {
    fields: {
      friend: {
        rule: "repeated",
        type: "Opponent",
        id: 1
      },
      inviteUrl: {
        type: "string",
        id: 2
      },
      inviteSubject: {
        type: "string",
        id: 3
      },
      inviteMessage: {
        type: "string",
        id: 4
      },
      self: {
        type: "Opponent",
        id: 5
      },
      maxInvites: {
        type: "int32",
        id: 6
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  GamesGetMissingPredictionsRequest: {
    fields: {
      stream: {
        type: "bool",
        id: 1
      }
    }
  },
  GamesGetMissingPredictionsResponse: {
    fields: {
      section: {
        rule: "repeated",
        type: "Group",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      },
      Group: {
        fields: {
          gameId: {
            type: "string",
            id: 1
          },
          title: {
            type: "string",
            id: 2
          },
          match: {
            rule: "repeated",
            type: "Match",
            id: 3
          }
        }
      }
    }
  },
  GamesGetPrivateInputsRequest: {
    fields: {}
  },
  GamesGetPrivateInputsResponse: {
    fields: {
      competition: {
        rule: "repeated",
        type: "Group",
        id: 1
      },
      friend: {
        rule: "repeated",
        type: "Opponent",
        id: 2
      },
      creditOption: {
        rule: "repeated",
        type: "Currency",
        id: 3
      },
      drinkOption: {
        rule: "repeated",
        type: "Currency",
        id: 4
      },
      creditBalance: {
        type: "Currency",
        id: 5
      },
      nonce: {
        type: "string",
        id: 6
      },
      maxInvitees: {
        type: "int32",
        id: 7
      },
      firstPrizeMultiplier: {
        rule: "repeated",
        type: "float",
        id: 8
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    reserved: [
      [
        9,
        9
      ]
    ],
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_EXPIRED: 2
        }
      },
      Group: {
        fields: {
          round: {
            rule: "repeated",
            type: "Round",
            id: 2
          },
          groupId: {
            type: "string",
            id: 4
          },
          title: {
            type: "string",
            id: 5
          },
          multiCompetition: {
            type: "bool",
            id: 6
          }
        },
        reserved: [
          [
            1,
            1
          ],
          [
            3,
            3
          ]
        ]
      },
      Round: {
        fields: {
          roundId: {
            type: "string",
            id: 1
          },
          roundName: {
            type: "string",
            id: 2
          },
          match: {
            rule: "repeated",
            type: "Match",
            id: 4
          },
          sponsoredMatch: {
            type: "Match",
            id: 5
          }
        },
        reserved: [
          [
            3,
            3
          ]
        ]
      }
    }
  },
  GamesGetPublicInputsRequest: {
    fields: {
      templateId: {
        type: "string",
        id: 1
      }
    }
  },
  GamesGetPublicInputsResponse: {
    fields: {
      template: {
        type: "GameTemplate",
        id: 1
      },
      nonce: {
        type: "string",
        id: 3
      },
      templateDetails: {
        type: "GameDetails",
        id: 4
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    reserved: [
      [
        2,
        2
      ]
    ],
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_EXPIRED: 2
        }
      }
    }
  },
  GamesGetTemplatesRequest: {
    fields: {
      stream: {
        type: "bool",
        id: 1
      }
    }
  },
  GamesGetTemplatesResponse: {
    fields: {
      publicGame: {
        rule: "repeated",
        type: "GameTemplate",
        id: 1,
        options: {
          deprecated: true
        }
      },
      jackpotGame: {
        rule: "repeated",
        type: "GameTemplate",
        id: 2
      },
      notification: {
        rule: "repeated",
        type: "Notification",
        id: 3
      },
      jackpotTitle: {
        type: "string",
        id: 4
      },
      jackpotMessage: {
        type: "string",
        id: 5
      },
      jackpotSponsorLogo: {
        type: "string",
        id: 6
      },
      publicGroup: {
        rule: "repeated",
        type: "Game",
        id: 7
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  GamesInviteFriendsRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      invitedFriend: {
        rule: "repeated",
        type: "string",
        id: 2
      }
    }
  },
  GamesInviteFriendsResponse: {
    fields: {
      gameDetails: {
        type: "GameDetails",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  GamesMuteNotificationsRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      muted: {
        type: "bool",
        id: 2
      }
    }
  },
  GamesMuteNotificationsResponse: {
    fields: {
      gameDetails: {
        type: "GameDetails",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  GamesSendMessageRequest: {
    fields: {
      gameId: {
        type: "string",
        id: 1
      },
      message: {
        type: "string",
        id: 2
      },
      parentKey: {
        type: "string",
        id: 3
      }
    },
    reserved: [
      [
        4,
        4
      ]
    ]
  },
  GamesSendMessageResponse: {
    fields: {
      remoteMessageId: {
        type: "string",
        id: 1
      },
      finalMessage: {
        type: "string",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  GroupsCreatePrivateRequest: {
    fields: {
      competitionId: {
        type: "string",
        id: 1
      },
      title: {
        type: "string",
        id: 2
      },
      welcomeMessage: {
        type: "string",
        id: 3
      },
      entryFee: {
        type: "int32",
        id: 5
      },
      nonce: {
        type: "string",
        id: 6
      },
      inviteeId: {
        rule: "repeated",
        type: "string",
        id: 7
      },
      teamId: {
        rule: "repeated",
        type: "string",
        id: 8
      }
    },
    reserved: [
      [
        4,
        4
      ]
    ]
  },
  GroupsCreatePrivateResponse: {
    fields: {
      groupGame: {
        type: "Game",
        id: 1
      },
      inviteUrl: {
        type: "string",
        id: 2
      },
      inviteSubject: {
        type: "string",
        id: 3
      },
      inviteMessage: {
        type: "string",
        id: 4
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_INSUFFICIENT_BALANCE: 2,
          WARNING_RETRY_LATER: 3
        }
      }
    }
  },
  GroupsGetActiveRequest: {
    fields: {
      stream: {
        type: "bool",
        id: 1
      }
    }
  },
  GroupsGetActiveResponse: {
    fields: {
      group: {
        rule: "repeated",
        type: "Group",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      notification: {
        rule: "repeated",
        type: "Notification",
        id: 3
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      },
      Group: {
        fields: {
          formattedTitle: {
            type: "string",
            id: 1
          },
          game: {
            rule: "repeated",
            type: "Game",
            id: 2
          }
        }
      }
    }
  },
  GroupsGetHistoryRequest: {
    fields: {
      stream: {
        type: "bool",
        id: 1
      },
      sortId: {
        type: "string",
        id: 2
      },
      markSeen: {
        type: "bool",
        id: 3
      }
    }
  },
  GroupsGetHistoryResponse: {
    fields: {
      groupGame: {
        rule: "repeated",
        type: "Game",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      sort: {
        rule: "repeated",
        type: "Sort",
        id: 3
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  GroupsGetPrivateInputsRequest: {
    fields: {}
  },
  GroupsGetPrivateInputsResponse: {
    fields: {
      competition: {
        rule: "repeated",
        type: "GroupCompetition",
        id: 1
      },
      creditOption: {
        rule: "repeated",
        type: "Currency",
        id: 3
      },
      creditBalance: {
        type: "Currency",
        id: 5
      },
      nonce: {
        type: "string",
        id: 6
      },
      maxInvitees: {
        type: "int32",
        id: 7
      },
      welcomeMessageTemplate: {
        type: "string",
        id: 9
      },
      friend: {
        rule: "repeated",
        type: "Opponent",
        id: 10
      },
      prizeDistribution: {
        rule: "repeated",
        type: "SimplePrizeLevel",
        id: 11
      },
      teamOption: {
        rule: "repeated",
        type: "TeamOptionGroup",
        id: 12
      },
      teamStartDate: {
        type: "int64",
        id: 13
      },
      teamEndDate: {
        type: "int64",
        id: 14
      },
      teamWelcomeMessageTemplate: {
        type: "string",
        id: 15
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    reserved: [
      [
        2,
        2
      ],
      [
        4,
        4
      ],
      [
        8,
        8
      ]
    ],
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      },
      GroupCompetition: {
        fields: {
          competition: {
            type: "Competition",
            id: 1
          },
          startTime: {
            type: "int64",
            id: 2
          },
          endTime: {
            type: "int64",
            id: 3
          }
        }
      },
      SimplePrizeLevel: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          value: {
            type: "string",
            id: 2
          }
        }
      },
      TeamOptionGroup: {
        fields: {
          competition: {
            type: "Competition",
            id: 1
          },
          team: {
            rule: "repeated",
            type: "Team",
            id: 2
          }
        }
      }
    }
  },
  MatchesGetAllTeamsRequest: {
    fields: {}
  },
  MatchesGetAllTeamsResponse: {
    fields: {
      group: {
        rule: "repeated",
        type: "Group",
        id: 1
      },
      defaultGroupIndex: {
        type: "int32",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      },
      Group: {
        fields: {
          competition: {
            type: "Competition",
            id: 1
          },
          team: {
            rule: "repeated",
            type: "Team",
            id: 2
          },
          defaultTeamIndex: {
            type: "int32",
            id: 3
          }
        }
      }
    }
  },
  MatchesGetRoundRequest: {
    fields: {
      roundId: {
        type: "string",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      }
    }
  },
  MatchesGetRoundResponse: {
    fields: {
      match: {
        rule: "repeated",
        type: "Match",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  MatchesGetTimelineRequest: {
    fields: {
      matchId: {
        type: "string",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      }
    }
  },
  MatchesGetTimelineResponse: {
    fields: {
      timeline: {
        type: "MatchTimeline",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  SessionsLoginRequest: {
    fields: {
      onesignalPlayerId: {
        type: "string",
        id: 1
      },
      attribution: {
        type: "Attribution",
        id: 2
      },
      openedUrl: {
        type: "string",
        id: 3
      }
    }
  },
  SessionsLoginResponse: {
    fields: {
      ownProfile: {
        type: "UserProfile",
        id: 1
      },
      preloadUrl: {
        rule: "repeated",
        type: "string",
        id: 2
      },
      link: {
        rule: "repeated",
        type: "Link",
        id: 4
      },
      creditProduct: {
        rule: "repeated",
        type: "StoreCreditProduct",
        id: 5
      },
      notifications: {
        type: "NotificationSettings",
        id: 6
      },
      identityToken: {
        type: "string",
        id: 10
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 104
      }
    },
    reserved: [
      [
        3,
        3
      ],
      [
        7,
        7
      ],
      [
        8,
        8
      ],
      [
        9,
        9
      ],
      [
        102,
        102
      ],
      [
        103,
        103
      ]
    ],
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_USER_SETUP_NEEDED: 2,
          ERROR_SESSION_TERMINATED: 4,
          ERROR_APP_UPGRADE_REQUIRED: 5,
          ERROR_EMAIL_VERIFICATION_REQUIRED: 6
        },
        reserved: [
          [
            3,
            3
          ]
        ]
      }
    }
  },
  SessionsLogoutRequest: {
    fields: {
      onesignalPlayerId: {
        type: "string",
        id: 1
      }
    }
  },
  SessionsLogoutResponse: {
    fields: {
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  StoreGetProductsRequest: {
    fields: {}
  },
  StoreGetProductsResponse: {
    fields: {
      balance: {
        type: "Currency",
        id: 1
      },
      creditPackage: {
        rule: "repeated",
        type: "StoreCreditProduct",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  StoreVerifyReceiptRequest: {
    fields: {
      receipt: {
        type: "string",
        id: 1
      },
      productId: {
        type: "string",
        id: 2
      },
      purchase: {
        rule: "repeated",
        type: "TrackedPurchase",
        id: 3
      }
    },
    nested: {
      TrackedPurchase: {
        fields: {
          transactionId: {
            type: "string",
            id: 1
          },
          priceMicro: {
            type: "int64",
            id: 2
          },
          currency: {
            type: "string",
            id: 3
          }
        }
      }
    }
  },
  StoreVerifyReceiptResponse: {
    fields: {
      profile: {
        type: "UserProfile",
        id: 1
      },
      consumedTransactionId: {
        rule: "repeated",
        type: "string",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersAddFacebookFriendRequest: {
    fields: {
      userId: {
        type: "string",
        id: 1
      },
      facebookAccessToken: {
        type: "string",
        id: 2
      }
    }
  },
  UsersAddFacebookFriendResponse: {
    fields: {
      user: {
        type: "User",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersAddFriendRequest: {
    fields: {
      userId: {
        type: "string",
        id: 1
      }
    }
  },
  UsersAddFriendResponse: {
    fields: {
      user: {
        type: "User",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NOT_FOUND: 2
        }
      }
    }
  },
  UsersClaimBonusRequest: {
    fields: {
      bonusId: {
        type: "string",
        id: 1
      },
      email: {
        type: "string",
        id: 2
      }
    }
  },
  UsersClaimBonusResponse: {
    fields: {
      bonus: {
        type: "Bonus",
        id: 1
      },
      profile: {
        type: "UserProfile",
        id: 2
      },
      resultNotification: {
        type: "Notification",
        id: 3
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_INELIGIBLE: 2,
          ERROR_NOT_FOUND: 3
        }
      }
    }
  },
  UsersClaimLinkRequest: {
    fields: {
      openedUrl: {
        type: "string",
        id: 1
      }
    }
  },
  UsersClaimLinkResponse: {
    fields: {
      notfication: {
        rule: "repeated",
        type: "Notification",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersConfirmNotificationRequest: {
    fields: {
      notificationId: {
        type: "string",
        id: 1
      }
    }
  },
  UsersConfirmNotificationResponse: {
    fields: {
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersDeleteAccountRequest: {
    fields: {}
  },
  UsersDeleteAccountResponse: {
    fields: {
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersGetFacebookFriendsRequest: {
    fields: {
      accessToken: {
        type: "string",
        id: 1
      }
    }
  },
  UsersGetFacebookFriendsResponse: {
    fields: {
      facebookFriend: {
        rule: "repeated",
        type: "Opponent",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_INVALID_TOKEN: 2
        }
      }
    }
  },
  UsersGetFriendsRequest: {
    fields: {
      sortId: {
        type: "string",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      },
      userId: {
        type: "string",
        id: 3
      }
    }
  },
  UsersGetFriendsResponse: {
    fields: {
      friend: {
        rule: "repeated",
        type: "Opponent",
        id: 1
      },
      incomingRequest: {
        rule: "repeated",
        type: "Opponent",
        id: 2
      },
      pending: {
        rule: "repeated",
        type: "Opponent",
        id: 3
      },
      self: {
        type: "Opponent",
        id: 4
      },
      sort: {
        rule: "repeated",
        type: "Sort",
        id: 5
      },
      highlight: {
        type: "OpponentHighlight",
        id: 6
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersGetLeaderboardRequest: {
    fields: {
      sortId: {
        type: "string",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      }
    }
  },
  UsersGetLeaderboardResponse: {
    fields: {
      entry: {
        rule: "repeated",
        type: "Opponent",
        id: 1
      },
      self: {
        type: "Opponent",
        id: 2
      },
      sort: {
        rule: "repeated",
        type: "Sort",
        id: 3
      },
      highlight: {
        type: "OpponentHighlight",
        id: 4
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersGetNotificationsRequest: {
    fields: {}
  },
  UsersGetNotificationsResponse: {
    fields: {
      settings: {
        type: "NotificationSettings",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersGetProfileRequest: {
    fields: {
      userId: {
        type: "string",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      }
    }
  },
  UsersGetProfileResponse: {
    fields: {
      profile: {
        type: "UserProfile",
        id: 1
      },
      rivalHighlight: {
        type: "OpponentHighlight",
        id: 2
      },
      link: {
        rule: "repeated",
        type: "Link",
        id: 3
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NOT_FOUND: 2
        }
      }
    }
  },
  UsersGetStatusRequest: {
    fields: {
      since: {
        type: "int64",
        id: 1
      },
      stream: {
        type: "bool",
        id: 2
      }
    }
  },
  UsersGetStatusResponse: {
    fields: {
      pendingInvites: {
        type: "int32",
        id: 4
      },
      pendingFriendRequests: {
        type: "int32",
        id: 5
      },
      storeOffers: {
        type: "int32",
        id: 6
      },
      creditBalance: {
        type: "Currency",
        id: 8
      },
      timestamp: {
        type: "int64",
        id: 9
      },
      notification: {
        rule: "repeated",
        type: "Notification",
        id: 12
      },
      unseenGamesFinished: {
        type: "int32",
        id: 13
      },
      pendingGroupInvites: {
        type: "int32",
        id: 14
      },
      unseenGroupsFinished: {
        type: "int32",
        id: 15
      },
      missingPredictionCount: {
        type: "int32",
        id: 16
      },
      activeGroupCount: {
        type: "int32",
        id: 17
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    reserved: [
      [
        1,
        1
      ],
      [
        2,
        2
      ],
      [
        3,
        3
      ],
      [
        7,
        7
      ],
      [
        10,
        10
      ],
      [
        11,
        11
      ]
    ],
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersRemoveFriendRequest: {
    fields: {
      userId: {
        type: "string",
        id: 1
      }
    }
  },
  UsersRemoveFriendResponse: {
    fields: {
      user: {
        type: "User",
        id: 1
      },
      self: {
        type: "User",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NOT_FOUND: 2
        }
      }
    }
  },
  UsersSearchRequest: {
    fields: {
      searchTerm: {
        type: "string",
        id: 1
      }
    }
  },
  UsersSearchResponse: {
    fields: {
      searchResult: {
        rule: "repeated",
        type: "Opponent",
        id: 1
      },
      self: {
        type: "Opponent",
        id: 2
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1
        }
      }
    }
  },
  UsersSetNotificationRequest: {
    fields: {
      topicId: {
        type: "string",
        id: 1
      },
      enabled: {
        type: "bool",
        id: 2
      }
    }
  },
  UsersSetNotificationResponse: {
    fields: {
      settings: {
        type: "NotificationSettings",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_INVALID_TOPIC: 2
        }
      }
    }
  },
  UsersUpdateProfileRequest: {
    fields: {
      nickname: {
        type: "string",
        id: 1
      },
      photoUrl: {
        type: "string",
        id: 2
      },
      firstName: {
        type: "string",
        id: 3
      },
      lastName: {
        type: "string",
        id: 4
      },
      favoriteTeamId: {
        type: "string",
        id: 6
      },
      clearFirstName: {
        type: "bool",
        id: 7
      },
      clearLastName: {
        type: "bool",
        id: 8
      },
      complete: {
        type: "bool",
        id: 9
      },
      advertisementsEnabled: {
        type: "BoolPreference",
        id: 10
      }
    },
    reserved: [
      [
        5,
        5
      ]
    ]
  },
  UsersUpdateProfileResponse: {
    fields: {
      profile: {
        type: "UserProfile",
        id: 1
      },
      result: {
        type: "Result",
        id: 100
      },
      errorMessage: {
        type: "string",
        id: 101
      },
      errorTitle: {
        type: "string",
        id: 102
      }
    },
    nested: {
      Result: {
        values: {
          ERROR_UNKNOWN: 0,
          SUCCESS: 1,
          ERROR_NICK_IN_USE: 2,
          ERROR_NICK_INVALID: 3,
          ERROR_PHOTO_INVALID: 4
        }
      }
    }
  }
});

module.exports = $root;
