import { FC } from 'react'
import { BetOffer } from 'protos'
import { makeStyles } from '@material-ui/styles'

import tipicoDeLogoUrl from 'assets/tipico_de.svg'

interface Props {
  sponsor: BetOffer.ISponsor
  variant?: 'light' | 'dark'
}

const useStyles = makeStyles({
  root: {
    display: 'block',
  },
})

const BetOfferSponsorLogo: FC<Props> = ({ sponsor, variant = 'light' }) => {
  const classes = useStyles()

  let logoUrl = variant === 'light' ? sponsor.logoUrlLight! : sponsor.logoUrl!
  if (sponsor.sponsorId === '3') {
    logoUrl = tipicoDeLogoUrl
  }

  return (
    <img
      height={16}
      alt={sponsor.name!}
      className={classes.root}
      src={logoUrl}
    />
  )
}

export default BetOfferSponsorLogo
