import React, { FC } from 'react'
import cls from 'classnames'
import { useIntl } from 'hooks'
import { BetOffer } from 'protos'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { NaviLink, Typography } from 'tackl-material'
import { useFontSize } from './util'

const useStyles = makeStyles<Theme>(({ palette, typography, shape, customComponents }) => ({
  root: {
    width: 45,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'center',
    backgroundColor: '#27333A',
    border: `1px solid #141823`,
    color: palette.common.white,
    borderRadius: shape.borderRadius,
    ...customComponents.BetOfferMarket?.root,
  },
  text: {
    opacity: .5,
    ...customComponents.BetOfferMarket?.text,
  },
  bold: {
    fontWeight: typography.fontWeightBold,
    ...customComponents.BetOfferMarket?.bold,
  },
}))

const BetOfferMarket: FC<{ market?: BetOffer.IMarket | null }> = ({ market }) => {
  const { n } = useIntl()
  const classes = useStyles()
  const fontSize = useFontSize()

  return (
    <NaviLink
      target="_blank"
      className={classes.root}
      href={(market && market.url) || ''}
    >
      <Typography
        variant="body2"
        component="span"
        className={cls(fontSize.small, classes.text, classes.bold)}
      >
        {(market && market.odd && n(Number(market.odd))) || '-'}
      </Typography>
    </NaviLink>
  )
}

export default BetOfferMarket
