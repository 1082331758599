import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { makeStyles } from '@material-ui/styles'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox'
import { CheckboxChecked as CheckboxCheckedIcon, CheckboxUnchecked as CheckboxUncheckedIcon } from '../Icons'

const useStyles = makeStyles(({ palette }: Theme) => ({
  colorPrimary: {
    color: palette.brand.main,
  },
  colorDisabled: {
    color: palette.neutral[400].main,
  },
}))

const Checkbox: React.FC<MuiCheckboxProps> = props => {
  const { colorPrimary, colorDisabled } = useStyles()

  return (
    <MuiCheckbox
      disableRipple
      disableTouchRipple
      {...props}
      icon={<CheckboxUncheckedIcon fontSize="large" />}
      checkedIcon={(
        <CheckboxCheckedIcon
          color={props.disabled ? 'disabled' : 'primary'}
          fontSize="large"
          classes={{ colorPrimary, colorDisabled }}
        />
      )}
    />
  )
}

export default Checkbox
