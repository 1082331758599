import React, { FC, memo } from 'react'
import cls from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import jerseyBackSmall from './assets/jersey_back_small.png'
import jerseyFrontSmall from './assets/jersey_front_small.png'
import jerseyBackSmall2x from './assets/jersey_back_small@2x.png'
import jerseyFrontSmall2x from './assets/jersey_front_small@2x.png'
import jerseyBackSmall3x from './assets/jersey_back_small@3x.png'
import jerseyFrontSmall3x from './assets/jersey_front_small@3x.png'
import jerseyBackMedium from './assets/jersey_back_medium.png'
import jerseyFrontMedium from './assets/jersey_front_medium.png'
import jerseyBackMedium2x from './assets/jersey_back_medium@2x.png'
import jerseyFrontMedium2x from './assets/jersey_front_medium@2x.png'
import jerseyBackMedium3x from './assets/jersey_back_medium@3x.png'
import jerseyFrontMedium3x from './assets/jersey_front_medium@3x.png'
import jerseyBackLarge from './assets/jersey_back_large.png'
import jerseyFrontLarge from './assets/jersey_front_large.png'
import jerseyBackLarge2x from './assets/jersey_back_large@2x.png'
import jerseyFrontLarge2x from './assets/jersey_front_large@2x.png'
import jerseyBackLarge3x from './assets/jersey_back_large@3x.png'
import jerseyFrontLarge3x from './assets/jersey_front_large@3x.png'

type Props = {
  label?: string | null
  color?: string | null
  className?: string
  side?: 'back' | 'front',
  stripeOrigin?: 'left' | 'right'
  size?: 'small' | 'medium' | 'large'
}

const createBackgroundBreakpoints = (assets: [string, string, string], stripeOrigin?: 'left' | 'right') => ({
  maskImage: `url(${assets[0]})`,
  background: `
    linear-gradient(${stripeOrigin === 'right'? '-45' : '45'}deg, transparent 0%, transparent 40%, currentColor 40%, currentColor 57%, transparent 57%, transparent 100%),
    center / contain no-repeat url(${assets[0]})
  `,
  '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
    background: `
      linear-gradient(${stripeOrigin === 'right'? '-45' : '45'}deg, transparent 0%, transparent 40%, currentColor 40%, currentColor 57%, transparent 57%, transparent 100%),
      center / contain no-repeat url(${assets[1]})
    `,
  },
  '@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 292dpi)': {
    background: `
      linear-gradient(${stripeOrigin === 'right'? '-45' : '45'}deg, transparent 0%, transparent 40%, currentColor 40%, currentColor 57%, transparent 57%, transparent 100%),
      center / contain no-repeat url(${assets[2]})
    `,
  },
})

const useStyles = makeStyles<Theme, Props>(({ palette }) => ({
  root: ({ color }) => ({
    margin: 0,
    padding: 0,
    maskSize: 'contain',
    maskPosition: 'center',
    backgroundBlendMode: 'multiply, normal',
    color: color? `#${color}` : palette.secondary.main,
  }),
  sizeSmall: ({ side, stripeOrigin }) => ({
    width: 49,
    height: 56,
    ...createBackgroundBreakpoints(
      side === 'front'
        ? [jerseyFrontSmall, jerseyFrontSmall2x, jerseyFrontSmall3x]
        : [jerseyBackSmall, jerseyBackSmall2x, jerseyBackSmall3x]
      ,
      stripeOrigin,
    ),
  }),
  sizeMedium: ({ side, stripeOrigin }) => ({
    width: 88,
    height: 100,
    ...createBackgroundBreakpoints(
      side === 'front'
        ? [jerseyFrontMedium, jerseyFrontMedium2x, jerseyFrontMedium3x]
        : [jerseyBackMedium, jerseyBackMedium2x, jerseyBackMedium3x]
      ,
      stripeOrigin,
    ),
  }),
  sizeLarge: ({ side, stripeOrigin }) => ({
    width: 145,
    height: 165,
    ...createBackgroundBreakpoints(
      side === 'front'
        ? [jerseyFrontLarge, jerseyFrontLarge2x, jerseyFrontLarge3x]
        : [jerseyBackLarge, jerseyBackLarge2x, jerseyBackLarge3x]
      ,
      stripeOrigin,
    ),
  }),
}))

const JerseyStriped: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { size, className } = props

  return (
    <figure
      className={cls(className, classes.root, {
        [classes.sizeSmall]: size === 'small',
        [classes.sizeMedium]: !size || size === 'medium',
        [classes.sizeLarge]: size === 'large',
      })}
    />
  )
}

export default memo(JerseyStriped)
