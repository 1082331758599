import React, { FC, useContext, Suspense } from 'react'
import 'themes/base/fonts'

import routes from 'routes'
import { useIntl, usePageViewAnalytics, useThemeLoader } from 'hooks'
import Router from 'components/Router'
import AuthContext from 'contexts/AuthContext'
import { SessionsLoginResponse } from 'protos'
import { HelmetProvider } from 'react-navi-helmet'
import { View, NotFoundBoundary } from 'react-navi'
import { createBrowserHistoryWithDepth } from 'lib'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { LoadingLayer, NotFound, AuthFailed } from 'components'
import { BetSlipDrawerContextProvider } from 'contexts/BetSlipDrawerContext'
import { MatchDetailsDrawerContextProvider } from 'contexts/MatchDetailsDrawerContext'

const { ERROR_USER_SETUP_NEEDED } = SessionsLoginResponse.Result

const RouterContainer: FC = () => {
  const { t, language } = useIntl()
  const { userInfo, sessionsLoginError } = useContext(AuthContext)

  return (
    <BetSlipDrawerContextProvider>
      <MatchDetailsDrawerContextProvider>
        <HelmetProvider>
          <Router
            routes={routes}
            history={createBrowserHistoryWithDepth()}
            context={{ t, language, userId: userInfo?.userId || null, sessionsLoginError }}
          >
            <Suspense fallback={<LoadingLayer open />}>
              <NotFoundBoundary render={NotFound}>
                {
                  sessionsLoginError && sessionsLoginError.result !== ERROR_USER_SETUP_NEEDED
                    ? <AuthFailed />
                    : <View />
                }
              </NotFoundBoundary>
            </Suspense>
          </Router>
        </HelmetProvider>
      </MatchDetailsDrawerContextProvider>
    </BetSlipDrawerContextProvider>
  )
}

const App: FC = () => {
  usePageViewAnalytics('Landing')

  const { theme } = useThemeLoader()
  const { isAuthStateKnown } = useContext(AuthContext)

  if (!theme) {
    return null
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {
        isAuthStateKnown
          ? <RouterContainer />
          : <LoadingLayer open />
      }
    </MuiThemeProvider>
  )
}

export default App
