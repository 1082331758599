import React, { FC, ComponentProps, ReactNode } from 'react'
import cls from 'classnames'
import { Toolbar, makeStyles } from '@material-ui/core'
import { NaviLink } from 'tackl-material/Link'
import { Box } from '../Box'
import BackButton from './BackButton'
import { Typography } from '../Typography'
import AppBar from './AppBar'

type Props = Omit<ComponentProps<typeof AppBar>, 'title'> & {
  backHref?: ComponentProps<typeof NaviLink>['href']
  canGoBack?: boolean
  title?: ReactNode
  titleProps?: ComponentProps<typeof Typography>
  subtitle?: ReactNode
  subtitleProps?: ComponentProps<typeof Typography>
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    [breakpoints.up('md')]: {
      paddingTop: 50,
      paddingBottom: 25,
    },
  },
  titles: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
}))

const NavBar: FC<Props> = ({
  backHref,
  canGoBack = true,
  title,
  titleProps,
  subtitle,
  subtitleProps,
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <AppBar
      {...props}
      className={cls(className, classes.root)}
    >
      <Toolbar
        disableGutters
      >
        <Box
          px={1}
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flex={1}>
            {canGoBack && <BackButton href={backHref} />}
          </Box>
          <Box className={classes.titles}>
            {
              !!title && (
                <Typography
                  variant="h3"
                  component="h1"
                  color="inherit"
                  align="center"
                  {...titleProps}
                >
                  {title}
                </Typography>
              )
            }
            {
              !!subtitle && (
                <Typography
                  variant="body1"
                  component="h1"
                  color="inherit"
                  align="center"
                  {...subtitleProps}
                >
                  {subtitle}
                </Typography>
              )
            }
          </Box>
          <Box flex={1} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
