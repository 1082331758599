import React, { FC, useEffect, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import loadMessages from 'messages'
import config from 'services/config'
import IntlContext from './IntlContext'

const useLanguageState = createPersistedState('language')
const defaultLanguage = config.allowedLangs[0] || 'en'

const normalizeLang = (lang: string) => lang.split('-')[0]
const isAllowedLang = (lang: string) => config.allowedLangs.includes(lang)

const IntlContextProvider: FC = ({ children }) => {
  const preferredLangs = navigator.languages || [navigator.language]
  const firstMatchedLang = preferredLangs.map(normalizeLang).find(isAllowedLang) || defaultLanguage
  const [language, setLanguage] = useLanguageState(firstMatchedLang)
  const [messages, setMessages] = useState<Messages>()

  // NOTE: won't work if changing language from ui becomes a thing
  useEffect(() => {
    if (language !== firstMatchedLang) {
      setLanguage(firstMatchedLang)
    }
  }, [firstMatchedLang, language, setLanguage])

  useEffect(() => {
    loadMessages().then(setMessages)
  }, [])

  if (!messages) {
    return null
  }

  return (
    <IntlContext.Provider value={{ messages, language, setLanguage }}>
      {children}
    </IntlContext.Provider>
  )
}

export default IntlContextProvider
