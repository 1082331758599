import { useEffect } from 'react'
import { analyticsService } from 'services/global'

const usePageViewAnalytics = (screenName: string, condition = true) => {
  useEffect(() => {
    if (condition) {
      const { pathname, hash, search } = window.location
      const page_path = `${pathname}${search}${hash}`

      analyticsService.get().setCurrentScreen(page_path)
      analyticsService.get().logEvent('page_view', {
        page_path,
        page_title: screenName,
        page_location: pathname,
      })
    }
  }, [screenName, condition])
}

export default usePageViewAnalytics
